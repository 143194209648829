import React, { useState, useEffect } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import {
  Cookies,
  FormattedMessage,
  hostAPI,
  axios,
  StyleSheet,
  CircularProgress,
  authHeader,
  Navbar,
  Vnavbar,
  Footer,
  MobileView,
  SideBar,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Card,
  CardContent,
} from "../common";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

let Base_URL = `${hostAPI.getHost()}`;

const styless = StyleSheet.create({
  page: {
    //   flexDirection: 'row',
    //   backgroundColor: '#E4E4E4',
    padding: "15px",
    fontSize: "12px",
    lineHeight: "1.5",
  },
  card_wrapper: {
    maxWidth: 600,
    margin: "200px auto",
  },

  logo: {
    color: "#008046",
    textAlign: "center",
    fontSize: "18px",
    fontWeight: "800",
  },
  logo2: {
    color: "#2b335e",
    textAlign: "center",
    fontSize: "18px",
    fontWeight: "800",
  },
  invoiceTitle: {
    padding: 7,
    fontWeight: "800",
    paddingBottom: "10px",
  },
  input_left: {
    width: "63px",
    backgroundColor: "#fff",
    borderRadius: "16px 0 0 16px",
    borderLeft: "1px solid #3494E629",
    borderTop: "1px solid #3494E629",
    borderBottom: "1px solid #3494E629",
    borderRight: "none",
  },
  input_right: {
    borderRadius: "16px 16px 16px 16px",
    borderLeft: "1px solid #3494E629",
    borderRight: "1px solid #3494E629",
    borderTop: "1px solid #3494E629",
    borderBottom: "1px solid #3494E629",
  },
});

const ChangeLogo = () => {
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [isFilePicked, setIsFilePicked] = useState(false);

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    event.target.files[0] && setIsFilePicked(true);
  };

  const handleSubmission = async () => {
    // HANDLING FILE AS SENDING FILE INTO BACKEND

    if (!isFilePicked) return;
    setLoading(true);
    const formData = new FormData();
    formData.append("logo", selectedFile);

    try {
      let res = await axios({
        url: `${Base_URL}api/v1/private/user/business/photo`,
        method: "post",
        headers: authHeader(),
        data: formData,
      });

      if (res.data.code !== 200) {
        console.log("err", res.data.messages.toString());
        setMessage(res.data.messages.toString());
        setMessageType("error");
        setOpen(true);
      } else {
        setSelectedFile("");
        setIsFilePicked(false);

        setMessage(res.data.messages.toString());
        setMessageType("success");
        setOpen(true);
        window.location.reload(true);
      }
    } catch (error) {
      console.log("err", error.response.data.messages.toString());
      setMessageType("error");
      setMessage(error.response.data.messages.toString());
      setOpen(true);
    }
    setLoading(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleConfirm = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let res = await axios({
        url: `${Base_URL}api/v1/private/transaction/withdraw-money-bank-request/execute`,
        method: "post",
        headers: authHeader(),
        data: {
          // beneficiary_id: beneficiary_id,
          // amount: amount,
          // pin: pin,
        },
      });

      if (res.data.code !== 200) {
      } else {
        // setCashOutOne(false);
        // setCashOutTwo(false);
        // setCashOutThree(true);
        console.log("err", res.data.messages.toString());
        setMessage(res.data.messages.toString());
      }
    } catch (error) {
      console.log("err", error);
    }
    setLoading(false);
  };

  useEffect(() => {}, []);
  return (
    <React.Fragment>
      <div
        className="WebView"
        style={{ textAlign: `${localStorage.getItem("LocalTextAlign")}` }}
      >
        <Navbar />
        <div id="content">
          <div>
            <div
              className=" asideTabView"
              style={{ backgroundColor: "#F0F6FA" }}
            >
              <div className="col-lg-1 pt-4">
                <Vnavbar />
              </div>
              <br />
              <div className="pt-4">
                <SideBar />
              </div>
            </div>
            <div className="row" style={{ margin: "0" }}>
              <div
                className="col-lg-1 pt-4 asideDesktopView SideDesktopView"
                style={{ backgroundColor: "#F0F6FA" }}
              >
                <Vnavbar />
              </div>
              <br />
              <div
                className="col-lg-3 col-s-12 pt-4 asideDesktopView SideDesktopView"
                style={{ backgroundColor: "#F0F6FA" }}
              >
                <SideBar />
              </div>

              <div className="col-lg-8 col-s-12 pt-4">
                <div className="row">
                  <div className="col-lg-12">
                    <div style={styless.card_wrapper}>
                      <Card
                        style={{
                          boxShadow: "0px 0px 5px 1px #ddd",
                          borderRadius: 12,
                        }}
                      >
                        <CardContent>
                          <h3 class="text-5 font-weight-600 mb-3 mb-sm-4 text-center">
                            Change Logo
                          </h3>

                          <div className="form-group">
                            <div
                              className="input-group"
                              style={{ direction: "ltr" }}
                            >
                              <input
                                className="form-control "
                                style={styless.input_right}
                                required
                                type="file"
                                // value={amount}
                                onChange={changeHandler}
                              />
                            </div>
                            <div
                              className="input-group"
                              style={{ direction: "rtl" }}
                            >
                              <div className="text-center">
                                <button
                                  onClick={handleSubmission}
                                  className="btn btn-primary"
                                  style={{
                                    borderRadius: "50px",
                                    width: "100%",
                                    marginTop: 16,
                                  }}
                                  disabled={loading}
                                >
                                  {loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                  )}
                                  <FormattedMessage
                                    id="submit"
                                    defaultMessage="Submit"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    </div>

                    <Snackbar
                      open={open}
                      autoHideDuration={2000}
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      onClose={handleClose}
                    >
                      <Alert severity={messageType}>{message}</Alert>
                    </Snackbar>
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <MobileView />
    </React.Fragment>
  );
};

export default ChangeLogo;
