import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import LoginImg from "../../assets/images/loginS.svg";
import Logo from "../../assets/images/stpay.png";
import LogoWhite from "../../assets/images/stpay_logo_white.png";


import firebase from "../../firebaseConfig";

import "firebase/auth";

import {
  Form,
  NumberFormat,
  CheckButton,
  Input,
  AuthService,
  Cookies,
  MobileView,
  FormattedMessage,
  Language,
  $,
} from "../../common";
import { set } from "js-cookie";

const useStyles = makeStyles((theme) => ({}));

const SignUp = () => {
  const classes = useStyles();
  const history = useHistory();
  const [mobile, setMobile] = useState("");
  const [name, setName] = useState("");
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [businessType, setBusinessType] = useState("");
  const [nidFront, setNidFront] = useState("");
  const [nidBack, setNidBack] = useState("");
  const [selfie, setSelfie] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(false);

  const [mobilePart, setMobilePart] = useState(true);
  const [otpPart, setOtpPart] = useState(false);
  const [emailPart, setEmailPart] = useState(false);
  const [uid, setUid] = useState("");

  const handleMobile = (e) => {
    e.preventDefault();
    setLoading(true);

    console.log("loading", true);

    AuthService.regExistence(mobile).then((res) => {
      if (res.data.code === 200) {
        setMobilePart(false);
        setOtpPart(true);
        setMessage(false);
        console.log("mobile", res.data);
        setLoading(false);
      } else {
        setMessage(res.data.messages.toString());
        setLoading(false);
      }
    });
  };

  const handleOtp = (e) => {
    e.preventDefault();
    setLoading(true);

    var text = "";
    var possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < 20; i++)
      text =
        text + possible.charAt(Math.floor(Math.random() * possible.length));
    setUid(text);

    AuthService.firebaseAuthInfo(mobile, text, otp).then((res) => {
      if (res.data.code === 200) {
        setOtpPart(false);
        setEmailPart(true);
        console.log("firebase", res.data);
        setLoading(false);
      } else {
        setMessage(res.data.messages.toString());
        setLoading(false);
      }
    });
  };

  const handleEmailOtp = () => {
    AuthService.regVerify(
      mobile,
      name,
      email,
      password,
      passwordConfirmation,
      otp
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    // let uid = Math.random();

    AuthService.regCompletion(
      mobile,
      uid,
      name,
      email,
      password,
      passwordConfirmation,
      1,
      otp
    ).then((res) => {
      if (res.data.code === 200) {
        setEmailPart(false);
        setMobilePart(true);
        console.log("complete", res.data);
        setLoading(false);

        // history.push("/dashboard");
        if (res.data.data.token) {
          Cookies.set("Mtoken", res.data, { path: "/" }, { expires: 3 }); //Expire After 3 days
          localStorage.setItem("kyc", {
            kyc_status: res.data.data.kyb_status,
            kyb_status: res.data.data.kyb_status,
          });
          history.push("/kyc-status");
        }
      } else {
        setMessage(res.data.messages.toString());
        console.log("complete fail", res.data);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    if (user) {
      history.push("/dashboard");
    }
  }, []);

  return (
    <div>
      {/* <div id="preloader">
        <div data-loader="dual-ring"></div>
      </div> */}

      <header id="header" style={{ padding: "0 105px" }}>
        <div>
          <div className="header-row">
            <div className="header-column justify-content-start">
              <div className="logo" style={{ paddingLeft: "3%" }}>
                {" "}
                <a className="d-flex" href="/dashboard" title="">
                  <img src={LogoWhite} alt="" width={120} />
                </a>{" "}
              </div>
            </div>
            <Language />
          </div>
        </div>
      </header>

      <div
        id="main-wrapper"
        className="loginPage WebView"
        style={{ textAlign: `${localStorage.getItem("LocalTextAlign")}` }}
      >
        <div id="content" style={{ marginTop: -70 }}>
          <div className="container py-5">
            <div className="row no-gutters h-100">
              <div className="col-md-6">
                <img
                  src={LoginImg}
                  alt=""
                  style={{ width: "100%", marginLeft: -50 }}
                />
              </div>
              <div className="col-md-6 colOnMob">
                <div className="FormGroupStyle">
                  <img src={Logo} alt="" style={{ width: "40%" }} />
                  <h4
                    style={{
                      color: "#2B335E",
                      fontSize: "30px",
                      fontWeight: "500",
                    }}
                  >
                    Become a Merchant
                  </h4>
                </div>

                {mobilePart && (
                  <form onSubmit={handleMobile}>
                    <div
                      className="form-group FormGroupStyle"
                      style={{ paddingTop: "10px", direction: "ltr" }}
                    >
                      <label
                        htmlFor="emailAddress"
                        style={{
                          fontSize: "18px",
                          marginBottom: "20px",
                        }}
                      >
                        Please enter your Mobile Number"
                      </label>
                      <NumberFormat
                        placeholder="Enter your mobile number"
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                        name="mobile-no"
                        className="form-control InputStyle"
                        style={{ paddingLeft: "20px", paddingRight: "50px" }}
                        id="mobile-no"
                        data-bv-field="mobile-no"
                        required
                        format="+88 ### ### #####"
                        mask="_"
                      />
                      <span className="fas fa-mobile-alt field-icons"></span>
                    </div>
                    <div
                      className="FormGroupStyle"
                      style={{ paddingTop: "1px" }}
                    >
                      <button
                        className="btn btn-primary btn-block my-4"
                        style={{ borderRadius: "50px" }}
                        type="submit"
                        disabled={loading}
                      >
                        {loading && (
                          <span className="spinner-border spinner-border-sm"></span>
                        )}
                        Continue
                      </button>
                    </div>
                  </form>
                )}

                {otpPart && (
                  <form onSubmit={handleOtp}>
                    <div
                      className="form-group FormGroupStyle"
                      style={{ direction: "ltr" }}
                    >
                      <small htmlFor="otp" style={{ fontSize: "21px" }}>
                        <FormattedMessage
                          id="six-digits-verification"
                          defaultMessage="We have sent 6 digits verification code to
                                  your mobile number."
                        />
                      </small>
                      <div id="divOuter">
                        <div id="divInner">
                          <NumberFormat
                            //  placeholder="Enter Your OTP"
                            name="otp"
                            className="form-control InputStyle"
                            style={{
                              marginTop: "20px",
                              paddingLeft: "20px",
                            }}
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            required
                            autoComplete="true"
                            data-bv-field="otp"
                            id="partitioned"
                            mask="_"
                            maxLength="6"
                            type="password"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="FormGroupStyle"
                      style={{ paddingTop: "1px" }}
                    >
                      <button
                        className="btn btn-primary btn-block my-4"
                        style={{ borderRadius: "50px" }}
                        disabled={loading}
                      >
                        {loading && (
                          <span className="spinner-border spinner-border-sm"></span>
                        )}
                        Continue
                      </button>
                    </div>
                  </form>
                )}
                {emailPart && (
                  <Form onSubmit={handleSubmit}>
                    <div
                      className="form-group FormGroupStyle"
                      style={{ direction: "ltr" }}
                    >
                      <label
                        style={{
                          fontSize: "18px",
                          marginBottom: "20px",
                        }}
                      >
                        Please make sure all the information are correct and
                        match your personal ID
                      </label>
                      <Input
                        placeholder="Owner Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        name="name"
                        className="form-control MerchantInput"
                        style={{ marginBottom: "10px" }}
                        id="name"
                        data-bv-field="name"
                        required
                      />

                      <Input
                        placeholder="Business E-mail address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        name="email"
                        className="form-control MerchantInput"
                        style={{ marginBottom: "10px" }}
                        id="email"
                        data-bv-field="email"
                        required
                      />
                      <Input
                        placeholder="Enter Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        name="password"
                        type="password"
                        className="form-control MerchantInput"
                        style={{ marginBottom: "10px" }}
                        id="password"
                        data-bv-field="password"
                        required
                      />
                      <Input
                        placeholder="Re-write Password"
                        value={passwordConfirmation}
                        onChange={(e) =>
                          setPasswordConfirmation(e.target.value)
                        }
                        name="password_confirmation"
                        type="password"
                        className="form-control MerchantInput"
                        style={{ marginBottom: "10px" }}
                        id="password_confirmation"
                        data-bv-field="password_confirmation"
                        required
                      />
                    </div>

                    <div className="FormGroupStyle" style={{ marginLeft: 30 }}>
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="defaultChecked2"
                        value={check}
                        onChange={(e) => setCheck(!check)}
                        required
                      />
                      <label class="custom-control-label" for="defaultChecked2">
                        Accept terms and condition
                      </label>
                    </div>

                    <div
                      className="FormGroupStyle"
                      style={{ paddingTop: "1px" }}
                    >
                      <button
                        className="btn btn-primary btn-block my-4"
                        style={{ borderRadius: "50px" }}
                        type="submit"
                        disabled={loading}
                      >
                        {loading && (
                          <span className="spinner-border spinner-border-sm"></span>
                        )}
                        Continue
                      </button>
                    </div>
                  </Form>
                )}

                {/* <button onClick={handleEmailOtp}>verify</button> */}

                {message && (
                  <h2
                    style={{
                      color: "#c0392b",
                      padding: "15px 15px 0 15px",
                      textAlign: "center",
                      fontSize: "17px",
                    }}
                  >
                    {message}
                  </h2>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
