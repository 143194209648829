import React, { useEffect, useState } from "react";

import axios from "axios";
import hostAPI from "../services/GetHost";
import authHeader from "../services/auth-header";

import RefreshIcon from "@material-ui/icons/Refresh";
import WithdrawICON from "../assets/images/withdraw.svg";
import CurrencyExchange from "../assets/images/Currency Exchange.svg";
import receivedICON from "../assets/images/received.svg";
import Logo from "../assets/images/stpay.png";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import LoopIcon from "@material-ui/icons/Loop";
import {
  FormattedMessage,
  UserService,
  useHistory,
  Cookies,
  QRCode,
  IconButton,
  Button,
} from "../common";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import SwiperCore, { Pagination, Autoplay } from "swiper";
import "swiper/swiper-bundle.css";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";

SwiperCore.use([Pagination, Autoplay]);

export default function SideBar() {
  let history = useHistory();

  const [basicUserIfo, setBasicUserIfo] = useState("");
  const [profile, setProfile] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const [kycStatus, setKycStatus] = useState("");
  const [kybStatus, setKybStatus] = useState("");
  const [user_has_pin, set_user_has_pin] = useState(false);

  const [message, setmessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);

  const checkStatus = async () => {
    setLoading(true);
    let url = `${hostAPI.getHost()}api/v1/private/user/basic-information`;
    let res = await axios({
      url: url,
      method: "get",
      headers: authHeader(),
    });

    if (res.data.code === 200) {
      setKycStatus(res.data.data.user.kyc_status);
      setKybStatus(res.data.data.user.kyb_status);
      set_user_has_pin(res.data.data.user.user_has_pin);
      setLoading(false);
    }
  };
  const sendEmail = async () => {
    setEmailLoading(true);
    let url = `${hostAPI.getHost()}api/v1/kyb/sent-to-email`;
    let res = await axios({
      url: url,
      method: "get",
      headers: authHeader(),
    });
    console.log("res", res.data);
    if (res.data.code === 200) {
      setmessage("Email send successfully");
      setEmailLoading(false);
    }
    setmessage(res.data.messages);
    setEmailLoading(false);
  };

  const downloadQR = () => {
    const canvas = document.getElementById("QR-Code");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");

    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "QR-Code.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  useEffect(() => {
    checkStatus();

    UserService.getUserBasicInfo().then(
      (response) => {
        if (response && response.data && response.data.code === 200) {
          setBasicUserIfo(
            response &&
              response.data &&
              response.data.data &&
              response.data.data.user
          );
        }
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          Cookies.remove("Mtoken");
          localStorage.clear();
          history.push("/login");
          window.location.reload();
        }
      }
    );

    UserService.getUserBoard().then(
      (response) => {
        if (response && response.data && response.data.code === 200) {
          setProfile(
            response &&
              response.data &&
              response.data.data &&
              response.data.data.profile
          );
        }
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          Cookies.remove("Mtoken");
          localStorage.clear();
          history.push("/login");
          window.location.reload();
        }
      }
    );
  }, [refresh]);

  return (
    <React.Fragment>
      {/* For Desktop */}
      <aside className="asideDesktopView">
        <div
          className="bg-white shadow-md p-4 mb-3 gradientBalance"
          style={{ borderRadius: "8px" }}
        >
          <div style={{ marginTop: "10px" }}>
            <small
              className="font-weight-500"
              style={{ color: "#ffff", fontSize: "24px" }}
            >
              <FormattedMessage
                id="Account-Balance"
                defaultMessage="Account Balance"
              />
            </small>

            <IconButton
              size="small"
              style={{ color: "#fff", float: "right" }}
              onClick={(e) => setRefresh(!refresh)}
            >
              <RefreshIcon />
            </IconButton>

            <br />

            <div style={{ marginTop: "30px" }}>
              <small
                className="font-weight-500"
                style={{ color: "#ffff", fontSize: "44px" }}
              >
                <div className="balanceStyle">
                  {basicUserIfo &&
                    basicUserIfo.available_balance &&
                    basicUserIfo.available_balance[0].currency}{" "}
                  {basicUserIfo &&
                    basicUserIfo.available_balance &&
                    parseFloat(basicUserIfo.available_balance[0].balance).toFixed(
                      2
                    )}
                </div>
              </small>
            </div>
          </div>
          {/* kyc - {kycStatus} kyb - {kybStatus} */}

          {message && <Button style={{ marginTop: 30 }}>{message}</Button>}

          {!loading && (
            <div style={{ display: "block", marginTop: 20 }}>
              {(kycStatus == 0 ||
                kycStatus == 3 ||
                kycStatus == 2 ||
                kycStatus == 7 ||
                kycStatus == 4) && (
                <Button
                  variant="contained"
                  color="secondary"
                  // onClick={handleFilter}
                  className="btn btn-sm btn btn-secondary"
                  style={{
                    borderRadius: "50px",
                    float: "right",
                    marginLeft: "5px",
                    marginBottom: "5px",
                  }}
                  onClick={(e) =>
                    history.push(`/kyc-status?kyc_status=${kycStatus}`)
                  }
                >
                  Upload Kyc
                </Button>
              )}
              {kycStatus == 1 && user_has_pin == false && (
                <Button
                  variant="contained"
                  color="secondary"
                  // onClick={handleFilter}
                  className="btn btn-sm btn btn-secondary"
                  style={{
                    borderRadius: "50px",
                    float: "right",
                    marginLeft: "5px",
                    marginBottom: "5px",
                  }}
                  onClick={(e) => history.push(`/kyc-status?user_has_pin=1`)}
                >
                  Set Pin
                </Button>
              )}
              {(kycStatus == 1 || kycStatus == 10) &&
                (kybStatus == 0 || kybStatus == 9 || kybStatus == 10) && (
                  <Button
                    variant="contained"
                    color="secondary"
                    // onClick={handleFilter}
                    className="btn btn-sm btn btn-secondary"
                    style={{
                      borderRadius: "50px",
                      float: "right",
                      marginLeft: "5px",
                      marginBottom: "5px",
                    }}
                    onClick={(e) => history.push("/kyb-status")}
                  >
                    Upload Kyb
                  </Button>
                )}
              {(kybStatus == 2 || kybStatus == 4 || kycStatus == 10) && (
                <Button
                  variant="contained"
                  color="secondary"
                  // onClick={handleFilter}
                  className="btn btn-sm btn"
                  style={{
                    borderRadius: "50px",
                    float: "right",
                    marginRight: "5px",
                    marginBottom: "5px",
                    background: "#31495D",
                  }}
                >
                  Waiting for Approval
                </Button>
              )}
              {kybStatus == 3 && (
                <>
                  <Button
                    variant="contained"
                    color="secondary"
                    // onClick={handleFilter}
                    className="btn btn-sm btn-secondary"
                    style={{
                      borderRadius: "50px",
                      float: "right",
                      marginRight: "5px",
                      marginBottom: "5px",
                    }}
                    onClick={(e) => history.push("/signed_document")}
                  >
                    Upload Signed Doc
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    // onClick={handleFilter}
                    className="btn btn-sm btn-secondary"
                    style={{
                      borderRadius: "50px",
                      float: "right",
                      marginRight: "5px",
                      marginBottom: "5px",
                    }}
                    onClick={sendEmail}
                  >
                    {emailLoading ? "Sending....." : "Send Email"}
                    {/* {message && <CheckCircleIcon />} */}
                  </Button>
                </>
              )}
            </div>
          )}

          <div className="d-flex accountStyle">
            <p className="mr-auto" style={{ whiteSpace: "nowrap" }}>
              <FormattedMessage id="Account-No" defaultMessage="Account No." />
            </p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <p className="mr-auto">
              {/* {localStorage.getItem("accountNo")} */}
              {basicUserIfo &&
                basicUserIfo.available_balance &&
                basicUserIfo.available_balance[0].account_no}
            </p>
          </div>
        </div>

        <div
          className="bg-white shadow-md p-3 mb-3"
          style={{ display: "inline-flex", width: "100%", borderRadius: "8px" }}
        >
          <div className="divICON">
            <img src={WithdrawICON} alt="" style={{ paddingTop: "12px" }} />
          </div>
          <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
            <FormattedMessage
              id="home_page_withdraw"
              defaultMessage="Transfer"
            />
            <br />
            <h6 style={{ color: "#008046", fontWeight: "bold" }}>
              {/* BDT{" "} */}
              {basicUserIfo &&
                basicUserIfo.summary &&
                basicUserIfo.summary.withdrawn}
            </h6>
            <h6 style={{ color: "#1DBF73", fontWeight: "bold" }}>
              Commission:{" "}
              {basicUserIfo &&
                basicUserIfo.summary &&
                basicUserIfo.summary.withdrawn_commission}
            </h6>
          </div>
        </div>

        <div
          className="bg-white shadow-md p-3 mb-3"
          style={{ display: "inline-flex", width: "100%", borderRadius: "8px" }}
        >
          <div className="divICON">
            <img src={CurrencyExchange} alt="" style={{ paddingTop: "12px" }} />
          </div>
          <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
            <FormattedMessage id="refund_page_refund" defaultMessage="Refund" />
            <br />
            <h6 style={{ color: "#008046", fontWeight: "bold" }}>
              {/* BDT{" "} */}
              {basicUserIfo &&
                basicUserIfo.summary &&
                basicUserIfo.summary.refund}
            </h6>
          </div>
        </div>

        <div
          className="bg-white shadow-md rounded p-3 mb-3"
          style={{ display: "inline-flex", width: "100%" }}
        >
          <div className="divICON">
            <img src={receivedICON} alt="" style={{ paddingTop: "12px" }} />
          </div>
          <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
            <FormattedMessage id="Received-Payment" defaultMessage="Payment" />
            <br />
            <h6 style={{ color: "#1DBF73", fontWeight: "bold" }}>
              Online:{" "}
              {basicUserIfo &&
                basicUserIfo.summary &&
                basicUserIfo.summary.received_payment}
            </h6>
            <h6 style={{ color: "#1DBF73", fontWeight: "bold" }}>
              Offline{" "}
              {basicUserIfo &&
                basicUserIfo.summary &&
                basicUserIfo.summary.received_payment_2}
            </h6>
          </div>
        </div>

        <div
          className="bg-white shadow-md p-3 mb-3"
          style={{ display: "inline-flex", width: "100%", borderRadius: "8px" }}
        >
          {kycStatus === 1 && kybStatus == 1 && (
            <div className="row">
              <div
                className="col-MDD-6 col-SL-12"
                style={{ paddingLeft: "10px", paddingRight: "5px" }}
              >
                <div className="divQR">
                  {/* {localStorage.getItem("qrCode") ? ( */}
                  {basicUserIfo.qr_code_text ? (
                    <QRCode
                      id="QR-Code"
                      //  value={localStorage.getItem("qrCode")}
                      value={basicUserIfo.qr_code_text}
                      bgColor="#F0F6FA"
                      size={165}
                      level={"H"}
                      includeMargin={true}
                      //   renderAs={"canvas"}
                      //   renderAs={"svg"}
                      // imageSettings={{
                      //   src: basicUserIfo.profile_thumbnail,
                      //   x: null,
                      //   y: null,
                      //   height: 35,
                      //   width: 35,
                      //   excavate: true,
                      // }}
                    />
                  ) : null}

                  {/* <QRNormal value={localStorage.getItem("qrCode")} /> */}
                  <img src={Logo} alt="" width={50} />
                </div>
              </div>
              <div
                className="col-MDD-6 col-SL-12"
                style={{ paddingLeft: "10px", paddingRight: "45px" }}
              >
                <div>
                  <p>
                    <FormattedMessage
                      id="Personalised-QR"
                      defaultMessage="Personalised QR CODE"
                    />
                  </p>
                  <small style={{ fontWeight: "bold", fontSize: "14px" }}>
                    <FormattedMessage id="Details" defaultMessage="Details" />:
                  </small>{" "}
                  <br />
                  <small style={{ fontSize: "12px" }}>
                    {/* {localStorage.getItem("MfirstName")}  {localStorage.getItem("MlasttName")} */}
                    {profile && profile.name}
                  </small>{" "}
                  <br />
                  <small style={{ fontSize: "12px" }}>
                    {/* {localStorage.getItem("MmobileNo")} */}
                    {basicUserIfo.mobile_number}
                  </small>
                  <br />
                  <div style={{ paddingTop: "5px", paddingBottom: "9px" }}>
                    <small style={{ fontWeight: "bold", fontSize: "14px" }}>
                      <FormattedMessage
                        id="Account-Type"
                        defaultMessage="Account Type"
                      />
                    </small>{" "}
                    <br />
                    <small style={{ fontSize: "12px" }}>
                      {/* {localStorage.getItem("accountType")} */}
                      {basicUserIfo &&
                        basicUserIfo.available_balance &&
                        basicUserIfo.available_balance[0].account_type}
                    </small>
                  </div>
                  <div>
                    <a
                      onClick={downloadQR}
                      href="#"
                      className="btn btn-sm"
                      style={{
                        borderRadius: "50px",
                        marginRight: "auto",
                        border: "2px solid red",
                        color: "red",
                        fontWeight: "bold",
                        boxShadow: "none",
                      }}
                    >
                      <FormattedMessage
                        id="download_qr_code"
                        defaultMessage="Download"
                      />
                    </a>{" "}
                    <span></span>
                  </div>
                </div>
              </div>

              <br />
            </div>
          )}
        </div>
      </aside>

      {/* For Tablet */}
      <aside className="asideTabView">
        <div className="container-fluid">
          <div style={{ display: "flex" }}>
            <div className="swiperSlider" style={{ width: "100%" }}>
              <Swiper
                spaceBetween={10}
                slidesPerView={2}
                loop={true}
                autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
                }}
              >
                <SwiperSlide>
                  <div
                    className="bg-white shadow-md p-4 mb-3 gradientBalance"
                    style={{
                      borderRadius: "8px",
                      margin: "0 5px",
                      width: "100%",
                      height: "290px",
                    }}
                  >
                    <div style={{ marginTop: "10px" }}>
                      <small
                        className="font-weight-500"
                        style={{ color: "#ffff", fontSize: "24px" }}
                      >
                        <FormattedMessage
                          id="Account-Balance"
                          defaultMessage="Account Balance"
                        />
                      </small>
                      <br />
                      <div style={{ marginTop: "30px" }}>
                        <small
                          className="font-weight-500"
                          style={{ color: "#ffff", fontSize: "44px" }}
                        >
                          <div className="balanceStyle">
                            {basicUserIfo &&
                              basicUserIfo.available_balance &&
                              basicUserIfo.available_balance[0].currency}{" "}
                            {basicUserIfo &&
                              basicUserIfo.available_balance &&
                              basicUserIfo.available_balance[0].balance}
                          </div>
                        </small>
                      </div>
                    </div>
                    <div
                      className="d-flex"
                      style={{ paddingTop: "25%", fontSize: "20px" }}
                    >
                      <p className="mr-auto" style={{ whiteSpace: "nowrap" }}>
                        <FormattedMessage
                          id="Account-No"
                          defaultMessage="Account No."
                        />
                      </p>
                      <p>
                        {/* {localStorage.getItem("accountNo")} */}
                        {basicUserIfo &&
                          basicUserIfo.available_balance &&
                          basicUserIfo.available_balance[0].account_no}
                      </p>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div
                    className="bg-white shadow-md p-3 mb-3"
                    style={{
                      display: "inline-flex",
                      borderRadius: "8px",
                      margin: "0 5px",
                      width: "100%",
                    }}
                  >
                    <div className="divICON">
                      <img
                        src={WithdrawICON}
                        alt=""
                        style={{ paddingTop: "12px" }}
                      />
                    </div>
                    <div style={{ paddingLeft: "20px" }}>
                      <FormattedMessage
                        id="home_page_withdraw"
                        defaultMessage="Transfer"
                      />
                      <br />
                      {basicUserIfo &&
                        basicUserIfo.available_balance &&
                        basicUserIfo.available_balance[0].balance}
                      <h6 style={{ color: "#008046", fontWeight: "bold" }}>
                        BDT{" "}
                        {basicUserIfo &&
                          basicUserIfo.summary &&
                          basicUserIfo.summary.withdrawn}
                      </h6>
                    </div>
                  </div>

                  <div
                    className="bg-white shadow-md p-3 mb-3"
                    style={{
                      display: "inline-flex",
                      borderRadius: "8px",
                      margin: "0 5px",
                      width: "100%",
                    }}
                  >
                    <div className="divICON">
                      <img
                        src={CurrencyExchange}
                        alt=""
                        style={{ paddingTop: "12px" }}
                      />
                    </div>
                    <div style={{ paddingLeft: "20px" }}>
                      <FormattedMessage
                        id="refund_page_refund"
                        defaultMessage="Refund"
                      />
                      <br />
                      <h6 style={{ color: "#008046", fontWeight: "bold" }}>
                        {/* BDT{" "} */}
                        {basicUserIfo &&
                          basicUserIfo.summary &&
                          basicUserIfo.summary.refund}
                      </h6>
                    </div>
                  </div>

                  <div
                    className="bg-white shadow-md rounded p-3 mb-3"
                    style={{
                      display: "inline-flex",
                      margin: "0 5px",
                      width: "100%",
                    }}
                  >
                    <div className="divICON">
                      <img
                        src={receivedICON}
                        alt=""
                        style={{ paddingTop: "12px" }}
                      />
                    </div>
                    <div style={{ paddingLeft: "20px" }}>
                      <FormattedMessage
                        id="Received-Payment"
                        defaultMessage="Received Payment"
                      />
                      <br />
                      <h6 style={{ color: "#1DBF73", fontWeight: "bold" }}>
                        {/* BDT{" "} */}
                        {basicUserIfo &&
                          basicUserIfo.summary &&
                          basicUserIfo.summary.received_payment}
                      </h6>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  {" "}
                  <div
                    className="bg-white shadow-md p-3 mb-3"
                    style={{
                      display: "inline-flex",
                      width: "100%",
                      borderRadius: "8px",
                      margin: "0 5px",
                      height: "290px",
                    }}
                  >
                    <div className="divQR">
                      {/* {localStorage.getItem("qrCode") ? ( */}
                      {basicUserIfo.qr_code_text ? (
                        <QRCode
                          id="QR-Code"
                          //  value={localStorage.getItem("qrCode")}
                          value={basicUserIfo.qr_code_text}
                          bgColor="#F0F6FA"
                          size={153}
                          level={"H"}
                          includeMargin={true}
                          //  renderAs={"canvas"}
                          //   renderAs={"svg"}
                          // imageSettings={{
                          //   src: basicUserIfo.profile_thumbnail,
                          //   x: null,
                          //   y: null,
                          //   height: 35,
                          //   width: 35,
                          //   excavate: true,
                          // }}
                        />
                      ) : null}

                      {/* <QRNormal value={localStorage.getItem("qrCode")} /> */}
                      <img src={Logo} alt="" width={50} />

                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <a
                          onClick={downloadQR}
                          href="#"
                          className="btn btn-sm"
                          style={{
                            borderRadius: "50px",
                            marginRight: "auto",
                            marginTop: "30px",
                            border: "1px solid red",
                            color: "red",
                            boxShadow: "none",
                          }}
                        >
                          <FormattedMessage
                            id="download_qr_code"
                            defaultMessage="Download"
                          />
                        </a>
                        {/* <a
                          //    id="upload"
                          //    onClick={shareQR}
                          href="#"
                          className="btn btn-sm"
                          style={{
                            borderRadius: "50px",
                            border: "1px solid red",
                            color: "red",
                            marginLeft: "auto",
                            marginTop: "30px",
                            boxShadow: "none",
                          }}
                        >
                          <FormattedMessage 
                            id="share_qr_code"
                            defaultMessage="Share"
                          />
                        </a> */}
                      </div>
                    </div>
                    <br />
                    <div style={{ paddingLeft: "5px" }}>
                      <p>
                        <FormattedMessage
                          id="Personalised-QR"
                          defaultMessage="Personalised QR CODE"
                        />
                      </p>
                      <small style={{ fontWeight: "bold", fontSize: "14px" }}>
                        <FormattedMessage
                          id="Details"
                          defaultMessage="Details:"
                        />
                      </small>{" "}
                      <br />
                      <small style={{ fontSize: "12px" }}>
                        {/* {localStorage.getItem("MfirstName")}  {localStorage.getItem("MlasttName")} */}
                        {basicUserIfo.first_name} {basicUserIfo.last_name}
                      </small>{" "}
                      <br />
                      <small style={{ fontSize: "12px" }}>
                        {/* {localStorage.getItem("MmobileNo")} */}
                        {basicUserIfo.mobile_number}
                      </small>
                      <br />
                      <div style={{ paddingTop: "5px", paddingBottom: "9px" }}>
                        <small style={{ fontWeight: "bold", fontSize: "14px" }}>
                          <FormattedMessage
                            id="Account-Type"
                            defaultMessage="Account Type"
                          />
                        </small>{" "}
                        <br />
                        <small style={{ fontSize: "12px" }}>
                          {/* {localStorage.getItem("accountType")} */}
                          {basicUserIfo &&
                            basicUserIfo.available_balance &&
                            basicUserIfo.available_balance[0].account_type}
                        </small>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </aside>
    </React.Fragment>
  );
}
