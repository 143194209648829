import React, { useState, useEffect } from "react";
import axios from "axios";
import hostAPI from "../../services/GetHost";
import authHeader from "../../services/auth-header";
import {
  Navbar,
  Vnavbar,
  SideBar,
  //  Chart,
  Footer,
  MobileView,
  FormattedMessage,
  useHistory,
  StyleSheet,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
} from "../../common";

const styless = StyleSheet.create({
  box: {
    width: "100%",
    marginTop: 20,
    padding: 20,
    border: "1px solid #ddd",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: 15,
    boxShadow: "0px 0px 5px 3px #eee",
  },
  input: {
    marginBottom: 20,
  },
  image: {
    width: 200,
  },
  wrapper: {
    display: "flex",
  },
  left: {
    flex: 1,
    padding: "0px 10px",
  },
  right: {
    flex: 1,
    padding: "0px 10px",
  },
});

export default function KycStatus() {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const [ownerType, setOwnerType] = useState([]);
  const [types, setTypes] = useState([]);
  const [category, setCategory] = useState([]);
  const [bankList, setBankList] = useState([]);
  const [branchList, setBranchList] = useState([]);

  const [business_owner_type_id, set_business_owner_type_id] = useState("");
  const [business_name, set_business_name] = useState("");
  const [business_address_tl, set_business_address_tl] = useState("");
  const [business_address_cur, set_business_address_cur] = useState("");
  const [business_phone_no, set_business_phone_no] = useState("");
  const [daily_transaction_volume, set_daily_transaction_volume] = useState("");
  const [monthly_transaction_volume, set_monthly_transaction_volume] =
    useState("");
  const [monthly_transaction_number, set_monthly_transaction_number] =
    useState("");
  const [business_category_id, set_business_category_id] = useState("");
  const [beneficiary_account_name, set_beneficiary_account_name] = useState("");
  const [beneficiary_account_no, set_beneficiary_account_no] = useState("");
  const [beneficiary_bank_id, set_beneficiary_bank_id] = useState("");
  const [beneficiary_branch_id, set_beneficiary_branch_id] = useState("");
  const [attachment, set_attachment] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let beneficiary = {
        account_name: beneficiary_account_name,
        account_no: parseInt(beneficiary_account_no),
        bank_id: parseInt(beneficiary_bank_id),
        branch_id: parseInt(beneficiary_branch_id),
      };

      let data = {
        business_owner_type_id,
        business_name,
        business_address_tl,
        business_address_cur,
        business_phone_no,
        daily_transaction_volume,
        monthly_transaction_volume,
        monthly_transaction_number,
        business_category_id,
        beneficiary,
        documents: attachment,
      };

      console.log("data", data);

      let url = `${hostAPI.getHost()}api/v1/auth/signup/business-docs`;
      let res = await axios({
        url: url,
        method: "post",
        data: data,
        headers: authHeader(),
      });
      console.log("res", res);
      if (res.data.code !== 200) {
        setMessageType("danger");
      } else {
        setMessageType("success");

        set_business_owner_type_id("");
        set_business_name("");
        set_business_address_tl("");
        set_business_address_cur("");
        set_business_phone_no("");
        set_daily_transaction_volume("");
        set_monthly_transaction_volume("");
        set_monthly_transaction_number("");
        set_business_category_id("");
        set_beneficiary_account_name("");
        set_beneficiary_account_no("");
        set_beneficiary_bank_id("");
        set_beneficiary_branch_id("");
        set_attachment([]);
      }
      setMessage(res.data.messages.toString());

      setTimeout(() => {
        history.push("/signed_document");
      }, 3000);
    } catch (error) {
      console.log(error.response);
    }

    setLoading(false);
  };

  const getOwnerType = async () => {
    setLoading(true);
    try {
      let url = `${hostAPI.getHost()}api/v1/business/owner-type`;
      let res = await axios({
        url: url,
        method: "get",
      });

      console.log("owner", res.data.data.owner_types);
      setOwnerType(res.data.data.owner_types);
    } catch (error) {
      console.log(error.response);
    }
    setLoading(false);
  };

  const handleOwnerType = (e) => {
    let value = e.target.value;
    set_business_owner_type_id(value);
    let new_type = ownerType.filter((e) => e.id === value);
    if (new_type[0] && new_type[0].docs.length > 0) {
      console.log("new type", new_type[0].docs);
      setTypes(new_type[0].docs);
    } else {
      setTypes([]);
    }
  };

  const getCategories = async () => {
    setLoading(true);

    try {
      let url = `${hostAPI.getHost()}api/v1/business/categories`;
      let res = await axios({
        url: url,
        method: "get",
        headers: authHeader(),
      });

      console.log("Categories", res.data.data.categories);
      setCategory(res.data.data.categories);
    } catch (error) {
      console.log(error.response);
    }
    setLoading(false);
  };

  const getBankList = async () => {
    setLoading(true);

    try {
      let url = `${hostAPI.getHost()}api/v1/private/banks`;
      let res = await axios({
        url: url,
        method: "get",
        headers: authHeader(),
      });

      console.log("Banks", res.data.data.banks);
      setBankList(res.data.data.banks);
    } catch (error) {
      console.log(error.response);
    }
    setLoading(false);
  };

  const handleBank = async (e) => {
    setLoading(true);

    let bank_id = e.target.value;
    set_beneficiary_bank_id(bank_id);
    try {
      let url = `${hostAPI.getHost()}api/v1/bank/branches?bank_id=${bank_id}`;
      let res = await axios({
        url: url,
        method: "get",
        headers: authHeader(),
      });

      console.log("branch", res.data.data.branches);
      setBranchList(res.data.data.branches);
    } catch (error) {
      console.log(error.response);
    }
    setLoading(false);
  };

  const handleAttachment = (event, index, code) => {
    // console.log('all', index, code, file)
    // attachment.push({index, code, file})
    // console.log('all file', attachment)

    let files = event.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onload = (e) => {
      let file = e.target.result;
      attachment.push({ type: code, atttachment: file });
      console.log("all file", attachment);
    };
  };

  useEffect(() => {
    getOwnerType();
    getCategories();
    getBankList();
  }, []);

  return (
    <React.Fragment>
      <div>
        <div
          className="WebView"
          style={{ textAlign: `${localStorage.getItem("LocalTextAlign")}` }}
        >
          <Navbar />

          <div id="content">
            <div>
              <div
                className=" asideTabView"
                style={{ backgroundColor: "#F0F6FA" }}
              >
                <div className="col-lg-1 pt-4">
                  <Vnavbar />
                </div>
                <br />
                <div className="pt-4">
                  <SideBar />
                </div>
              </div>

              <div className="row" style={{ margin: "0" }}>
                <div
                  className="col-lg-1 pt-4 asideDesktopView SideDesktopView"
                  style={{ backgroundColor: "#F0F6FA" }}
                >
                  <Vnavbar />
                </div>
                <div
                  className="col-lg-3 col-s-12 pt-4 asideDesktopView SideDesktopView"
                  style={{ backgroundColor: "#F0F6FA" }}
                >
                  <SideBar />
                </div>

                <div className="col-lg-8 col-s-12 pt-4">
                  <h4
                    className="font-weight-600 mb-3"
                    style={{ color: "#2D335B" }}
                  >
                    Set KYB
                  </h4>

                  {message && (
                    <div className={`alert alert-${messageType}`} role="alert">
                      {message}
                    </div>
                  )}

                  <form onSubmit={handleSubmit}>
                    <div style={styless.box}>
                      <h3 className="text-5 font-weight-600 mb-3 mb-sm-4 text-center">
                        Business Information
                      </h3>
                      <div style={styless.wrapper}>
                        <div style={styless.left}>
                          <h6 style={styless.red}>Business Owner type</h6>
                          <FormControl variant="outlined" fullWidth>
                            <InputLabel htmlFor="type">
                              Business Owner type
                            </InputLabel>
                            <Select
                              required
                              style={{
                                borderRadius: 15,
                                marginBottom: 20,
                                height: 50,
                              }}
                              labelId="type"
                              label="Business Owner type"
                              id="demo-simple-select-outlined"
                              value={business_owner_type_id}
                              onChange={handleOwnerType}
                              // label="Test"
                            >
                              <MenuItem value="">
                                <em>Select Please</em>
                              </MenuItem>
                              {ownerType.map((item) => (
                                <MenuItem value={item.id}>{item.name}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          <h6 style={styless.red}>
                            Company/Organization/Institute name
                          </h6>
                          <input
                            type="text"
                            required
                            className="form-control MerchantInput"
                            placeholder="Name as per Trade License"
                            style={styless.input}
                            id="actual-btn"
                            value={business_name}
                            onChange={(e) => {
                              set_business_name(e.target.value);
                            }}
                          />
                          <h6 style={styless.red}>
                            Business Address (as per Trade License)
                          </h6>
                          <input
                            required
                            type="text"
                            className="form-control MerchantInput"
                            placeholder="Business Address (as per Trade License)"
                            style={styless.input}
                            id="actual-btn"
                            value={business_address_tl}
                            onChange={(e) => {
                              set_business_address_tl(e.target.value);
                            }}
                          />
                        </div>
                        <div style={styless.right}>
                          <h6 style={styless.red}>
                            Business Address (Current address)
                          </h6>
                          <input
                            required
                            type="text"
                            className="form-control MerchantInput"
                            placeholder="Business Address (Current address)"
                            style={styless.input}
                            id="actual-btn"
                            value={business_address_cur}
                            onChange={(e) => {
                              set_business_address_cur(e.target.value);
                            }}
                          />

                          <h6 style={styless.red}>Business Number</h6>
                          <input
                            required
                            type="number"
                            className="form-control MerchantInput"
                            placeholder="Business Number"
                            max="999999999999999"
                            style={styless.input}
                            id="actual-btn"
                            value={business_phone_no}
                            onChange={(e) => {
                              set_business_phone_no(e.target.value);
                            }}
                          />

                          <h6 style={styless.red}>Business Category</h6>
                          <FormControl variant="outlined" fullWidth>
                            <InputLabel htmlFor="category">
                              Business Category
                            </InputLabel>
                            <Select
                              required
                              style={{
                                borderRadius: 15,
                                marginBottom: 20,
                                height: 50,
                              }}
                              labelId="category"
                              id="category"
                              label="Business Category"
                              // input={<Input id="name-multiple" />}
                              value={business_category_id}
                              onChange={(e) =>
                                set_business_category_id(e.target.value)
                              }
                              // label="Test"
                            >
                              <MenuItem value="">
                                <em>Select Please</em>
                              </MenuItem>
                              {category.map((item) => (
                                <MenuItem value={item.id}>{item.name}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                      <h3 className="text-5 font-weight-600 mb-3 mb-sm-4 text-center">
                        Transaction Profile
                      </h3>
                      <div style={styless.wrapper}>
                        <div style={styless.left}>
                          <h6 style={styless.red}>Daily Transaction Volume</h6>
                          <input
                            required
                            type="number"
                            className="form-control MerchantInput"
                            placeholder="Daily Transaction Volume"
                            style={styless.input}
                            id="actual-btn"
                            value={daily_transaction_volume}
                            onChange={(e) => {
                              set_daily_transaction_volume(e.target.value);
                            }}
                          />

                          <h6 style={styless.red}>
                            Monthly Transaction Volume
                          </h6>
                          <input
                            required
                            type="number"
                            className="form-control MerchantInput"
                            placeholder="Monthly Transaction Volume"
                            style={styless.input}
                            id="actual-btn"
                            value={monthly_transaction_volume}
                            onChange={(e) => {
                              set_monthly_transaction_volume(e.target.value);
                            }}
                          />
                        </div>
                        <div style={styless.right}>
                          <h6 style={styless.red}>
                            Number of Transactions in a month
                          </h6>
                          <input
                            required
                            type="number"
                            className="form-control MerchantInput"
                            placeholder=" Number of Transactions in a month"
                            style={styless.input}
                            id="actual-btn"
                            value={monthly_transaction_number}
                            onChange={(e) => {
                              set_monthly_transaction_number(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <h3 className="text-5 font-weight-600 mb-3 mb-sm-4 text-center">
                        Bank Account Info
                      </h3>

                      <div style={styless.wrapper}>
                        <div style={styless.left}>
                          <h6 style={styless.red}>Account Name</h6>
                          <input
                            required
                            type="text"
                            className="form-control MerchantInput"
                            placeholder="Account Name"
                            style={styless.input}
                            id="actual-btn"
                            value={beneficiary_account_name}
                            onChange={(e) => {
                              set_beneficiary_account_name(e.target.value);
                            }}
                          />

                          <h6 style={styless.red}>Account Number</h6>
                          <input
                            required
                            type="number"
                            max="999999999999999999"
                            className="form-control MerchantInput"
                            placeholder="Account Number"
                            style={styless.input}
                            id="actual-btn"
                            value={beneficiary_account_no}
                            onChange={(e) => {
                              set_beneficiary_account_no(e.target.value);
                            }}
                          />
                        </div>
                        <div style={styless.right}>
                          <h6 style={styless.red}>Bank</h6>
                          <FormControl variant="outlined" fullWidth>
                            <InputLabel htmlFor="bank">Bank</InputLabel>
                            <Select
                              required
                              style={{
                                borderRadius: 15,
                                marginBottom: 20,
                                height: 50,
                              }}
                              labelId="bank"
                              label="bank"
                              // id="Bank"
                              value={beneficiary_bank_id}
                              onChange={handleBank}
                              // label="Test"
                            >
                              <MenuItem value="">
                                <em>Select Please</em>
                              </MenuItem>
                              {bankList.map((item) => (
                                <MenuItem value={item.bank_id}>
                                  {item.bank_name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          <h6 style={styless.red}>Branch</h6>
                          <FormControl variant="outlined" fullWidth>
                            <InputLabel htmlFor="branch">Branch</InputLabel>

                            <Select
                              required
                              style={{
                                borderRadius: 15,
                                marginBottom: 20,
                                height: 50,
                              }}
                              labelId="branch"
                              id="branch"
                              label="Branch"
                              value={beneficiary_branch_id}
                              onChange={(e) =>
                                set_beneficiary_branch_id(e.target.value)
                              }
                              // label="Test"
                            >
                              <MenuItem value="">
                                <em>Select Please</em>
                              </MenuItem>
                              {branchList.map((item) => (
                                <MenuItem value={item.branch_id}>
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                      {types.length > 0 && (
                        <h3 className="text-5 font-weight-600 mb-3 mb-sm-4 text-center">
                          Upload Docs
                        </h3>
                      )}
                      <div className="row">
                        {types &&
                          types.map((item, index) => (
                            <div className="col-md-6">
                              <h6 style={styless.red}>
                                {item.hint} {item.required && "*"}{" "}
                              </h6>
                              <input
                                type="file"
                                className="form-control MerchantInput"
                                style={styless.input}
                                id="actual-btn"
                                required={item.required}
                                onChange={(event) => {
                                  handleAttachment(event, index, item.code);
                                }}
                              />
                            </div>
                          ))}
                      </div>
                      <div className="" style={{ width: "50%" }}>
                        <button
                          className="btn btn-primary"
                          style={{
                            borderRadius: "50px",
                            width: "100%",
                          }}
                          type="submit"
                          // onClick={handleSubmit}
                        >
                          {loading && (
                            <span
                              className="spinner-border spinner-border-sm"
                              style={{ marginRight: 10 }}
                            ></span>
                          )}
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>

        <MobileView />
      </div>
    </React.Fragment>
  );
}
