import React, { useEffect, useState } from "react";
import {
  Cookies,
  useHistory,
  FormattedMessage,
  clsx,
  lighten,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  IconButton,
  Tooltip,
  FilterListIcon,
  UserService,
  PropTypes,
} from "../common";

const headCells = [
  // { id: 'color', numeric: false, disablePadding: true, label: 'color' },
  { id: "state", numeric: true, disablePadding: false, label: "State" },
  { id: "title", numeric: true, disablePadding: false, label: "Title" },
  {
    id: "sub_title",
    numeric: true,
    disablePadding: false,
    label: "Sub Title",
  },
  {
    id: "description",
    numeric: true,
    disablePadding: false,
    label: "Description",
  },
  {
    id: "created_at",
    numeric: true,
    disablePadding: false,
    label: "Created At",
  },

  // { id: 'type_of_tx', numeric: true, disablePadding: false, label: 'type_of_tx' },
];

function EnhancedTableHead(props) {
  const {
    classes,
    //  onSelectAllClick,
    order,
    orderBy,
    //   numSelected,
    //  rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          #
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              style={{ flexDirection: "column" }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;
  // const [value, setValue] = React.useState(null);

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          <FormattedMessage
            id="app.Notifications"
            defaultMessage="Notifications"
          />
        </Typography>
      )}

      {/* {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) :
       ( */}
      <Tooltip title="Filter list">
        <IconButton aria-label="filter list">
          <FilterListIcon />
        </IconButton>
      </Tooltip>
      {/* )
      } */}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(1),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

// const useModelStyles = makeStyles((theme) => ({
//   modal: {
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//   },
//   paper: {
//     backgroundColor: theme.palette.background.paper,
//     border: "2px solid #000",
//     boxShadow: theme.shadows[5],
//     padding: theme.spacing(2, 4, 3),
//     overflowY: "scroll",
//     // height: "70vh",
//     height: "-webkit-fill-available;",
//   },
// }));
// const filter = createFilterOptions();

export default function EnhancedTable(props) {
  let history = useHistory();

  const classes = useStyles();
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("created_at");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  //const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [totalRows, setTotalRows] = useState(0);

  //const [userTransactions, setUserTransaction] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [NoData, setNoData] = React.useState(false);

  useEffect(() => {
    UserService.getNotifications(page + 1).then(
      (response) => {
        if (response.data.code === 200) {
          if (response.data.data.notifications.length > 0) {
            setTotalRows(
              response &&
                response.data &&
                response.data.data_additional &&
                response.data.data_additional.total
            );
            setNotifications(response.data.data.notifications);
          } else {
            document.getElementById("noNotifications").style.display = "none";
            setNoData(true);
          }
        }
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          Cookies.remove("Mtoken");
          localStorage.clear();
          history.push("/login");
          window.location.reload();
        } else if (
          notifications === error.response ||
          error.response.data ||
          error.response.data.messages ||
          error.message ||
          error.toString()
        ) {
          Cookies.remove("Mtoken");
          localStorage.clear();
          history.push("/login");
          window.location.reload();
        }
      }
    );
  }, [page]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = notifications.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 50));
    setPage(0);
  };

  // const handleChangeDense = (event) => {
  //   setDense(event.target.checked);
  // };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // const emptyRows =
  //   rowsPerPage -
  //   Math.min(rowsPerPage, notifications.length - page * rowsPerPage);

  // const modelclasses = useModelStyles();

  // const [open, setOpen] = React.useState(false);

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  // const [userTrans, setUserTrans] = useState("");

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          {NoData && (
            <div style={{ padding: "20px" }}>
              <small style={{ fontSize: "16px" }}>
                <FormattedMessage
                  id="No_Notifications"
                  defaultMessage="No Notifications"
                />
              </small>
            </div>
          )}
          <div id="noNotifications">
            {/* <EnhancedTableToolbar  /> */}
            <TableContainer>
              {/* <TableCell>
    <Autocomplete
        id="standard-basic"
        inputTypeSearch
        options={userTransactions.map((option) => option.amount)}
        renderInput={(params) => (
          <TextField {...params} label="search" margin="normal"       
            InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }} />
        )}

      />
   </TableCell> */}

              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={"medium"}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={notifications.length}
                />
                <TableBody>
                  {notifications &&
                    notifications.length > 0 &&
                    notifications.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          onClick={(event) =>
                            handleClick(event, row.transaction_id)
                          }
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          {/* 
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      
                      </TableCell> */}

                          <TableCell
                            align="center"
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {<img src={row.icon} width={27} alt="" />}
                          </TableCell>
                          <TableCell align="center">{row.title}</TableCell>

                          <TableCell align="center">{row.sub_title}</TableCell>
                          <TableCell align="center">
                            {row.description}
                          </TableCell>
                          <TableCell align="center">{row.created_at}</TableCell>
                          {/* <TableCell align="left">
                        {row.source ? row.source.name : null}
                      </TableCell>
                      <TableCell align="left">
                        {row.source ? row.source.mobile_no : null}
                      </TableCell>
                      <TableCell align="left">
                        {row.destination ? row.destination.name : null}
                      </TableCell>
                      <TableCell align="left">
                        {row.destination ? row.destination.mobile_no : null}
                      </TableCell> */}
                          {/* <TableCell align="left">{row.type_of_tx}</TableCell> */}
                        </TableRow>
                      );
                    })}
                  {/* {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalRows}
              page={page}
              onChangePage={handleChangePage}
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPageOptions={[50]}
              style={{ direction: "ltr" }}
            />
          </div>
        </Paper>
        {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
      </div>
    </React.Fragment>
  );
}
