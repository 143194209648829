import React, { useState, useEffect } from "react";
import axios from "axios";
import hostAPI from "../services/GetHost";
import authHeader from "../services/auth-header";

import {
  Transaction,
  Navbar,
  Vnavbar,
  SideBar,
  //  Chart,
  Footer,
  MobileView,
  FormattedMessage,
  useHistory,
} from "../common";

export default function Dashboard() {
  const history = useHistory();


  useEffect(() => {
  }, []);

  return (
    <React.Fragment>
      <div>
        <div
          className="WebView"
          style={{ textAlign: `${localStorage.getItem("LocalTextAlign")}` }}
        >
          <Navbar />

          <div id="content">
            <div>
              <div
                className=" asideTabView"
                style={{ backgroundColor: "#F0F6FA" }}
              >
                <div className="col-lg-1 pt-4">
                  <Vnavbar />
                </div>
                <br />
                <div className="pt-4">
                  <SideBar />
                </div>
              </div>

              <div className="row" style={{ margin: "0" }}>
                <div
                  className="col-lg-1 pt-4 asideDesktopView SideDesktopView"
                  style={{ backgroundColor: "#F0F6FA" }}
                >
                  <Vnavbar />
                </div>
                <div
                  className="col-lg-3 col-s-12 pt-4 asideDesktopView SideDesktopView"
                  style={{ backgroundColor: "#F0F6FA" }}
                >
                  <SideBar />
                </div>

                <div className="col-lg-8 col-s-12 pt-4">
                  <h4
                    className="font-weight-600 mb-3"
                    style={{ color: "#2D335B" }}
                  >
                    <FormattedMessage
                      id="home_page_latest_transactions"
                      defaultMessage="Latest Transaction"
                    />
                  </h4>
                  <Transaction />
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>

        <MobileView />
      </div>
    </React.Fragment>
  );
}
