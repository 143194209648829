import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyDADShZkVgyp82tr4NqnfChYdWcN_SFmgc",
  authDomain: "deshipay.firebaseapp.com",
  projectId: "deshipay",
  storageBucket: "deshipay.appspot.com",
  messagingSenderId: "390167511070",
  appId: "1:390167511070:web:822dfabdb625d636bc2e0f",
  measurementId: "G-72YHCQ1Q3D",
};

    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);

  export default firebase;