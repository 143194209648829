import React, { useState, useEffect } from "react";
import axios from "axios";
import authHeader from "../../services/auth-header";
import hostAPI from "../../services/GetHost";
import {
  Navbar,
  Vnavbar,
  SideBar,
  //  Chart,
  Footer,
  MobileView,
  FormattedMessage,
  useHistory,
  StyleSheet,
  Button,
} from "../../common";

const styless = StyleSheet.create({
  box: {
    width: 600,
    marginTop: 100,
    padding: 40,
    border: "1px solid #ddd",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: 15,
    boxShadow: "0px 0px 5px 3px #eee",
  },
  input: {
    marginBottom: 20,
  },
  image: {
    width: 200,
  },
});

export default function KycStatus() {
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [attachment, set_attachment] = useState(null);
  const [image, setImage] = useState("");

  const [successScreen, setSuccessScreen] = useState(false);
  const [message, setMessage] = useState("");

  const loadFile = function (event) {
    var image = document.getElementById("output");
    image.src = URL.createObjectURL(event.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let data = new FormData();

      data.append("signed_document", attachment);

      let url = `${hostAPI.getHost()}api/v1/kyb/signed-doc-upload`;
      let res = await axios({
        url: url,
        method: "post",
        data: data,
        headers: authHeader(),
      });

      console.log("res", res.data);
      if (res.data.code === 200) {
        setSuccessScreen(true);
        setTimeout(() => {
          history.push("/dashboard");
        }, 3000);
      } else {
        setMessage(res.data.messages.toString());
      }
    } catch (error) {
      console.log(error.response);
    }

    setLoading(false);
  };

  useEffect(() => {}, []);

  return (
    <React.Fragment>
      <div>
        <div
          className="WebView"
          style={{ textAlign: `${localStorage.getItem("LocalTextAlign")}` }}
        >
          <Navbar />

          <div id="content">
            <div>
              <div
                className=" asideTabView"
                style={{ backgroundColor: "#F0F6FA" }}
              >
                <div className="col-lg-1 pt-4">
                  <Vnavbar />
                </div>
                <br />
                <div className="pt-4">
                  <SideBar />
                </div>
              </div>

              <div className="row" style={{ margin: "0" }}>
                <div
                  className="col-lg-1 pt-4 asideDesktopView SideDesktopView"
                  style={{ backgroundColor: "#F0F6FA" }}
                >
                  <Vnavbar />
                </div>
                <div
                  className="col-lg-3 col-s-12 pt-4 asideDesktopView SideDesktopView"
                  style={{ backgroundColor: "#F0F6FA" }}
                >
                  <SideBar />
                </div>

                <div className="col-lg-8 col-s-12 pt-4">
                  <h4
                    className="font-weight-600 mb-3"
                    style={{ color: "#2D335B" }}
                  >
                    Set Signed Document
                  </h4>

                  {message && (
                    <div className={`alert alert-danger`} role="alert">
                      {message}
                    </div>
                  )}

                  {!successScreen && (
                    <div style={styless.box}>
                      <form onSubmit={handleSubmit}>
                        <h3 className="text-5 font-weight-600 mb-3 mb-sm-4 text-center">
                          Upload Signed Document
                        </h3>
                        <h6 style={styless.red}>Signed Document</h6>
                        <input
                          type="file"
                          required
                          className="form-control MerchantInput"
                          style={styless.input}
                          id="actual-btn"
                          onChange={(e) => {
                            set_attachment(e.target.files[0]);
                            loadFile(e);
                          }}
                          name="attachment"
                        />

                        <img
                          src={image}
                          alt=""
                          id="output"
                          style={styless.image}
                        />

                        <button
                          className="btn btn-primary"
                          style={{
                            borderRadius: "50px",
                            width: "100%",
                          }}
                          type="submit"
                        >
                          {loading && (
                            <span
                              className="spinner-border spinner-border-sm"
                              style={{ marginRight: 10 }}
                            ></span>
                          )}
                          Continue
                        </button>
                      </form>
                    </div>
                  )}

                  {successScreen && (
                    <div style={styless.box}>
                      <h3 className="text-5 font-weight-600 mb-3 mb-sm-4 text-center">
                        Successfully Done
                      </h3>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>

        <MobileView />
      </div>
    </React.Fragment>
  );
}
