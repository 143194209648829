import React from "react";
import ReactDOM from "react-dom";

import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import {
  isAuthenticate,
  Login,
  Dashboard,
  Transactions,
  BasicInfo,
  ForgotPassword,
  RouteWithLayout,
  PrivateRouteWithLayout,
  Main,
} from "./common-counter";
import "./App-counter.css";

import "./assets/vendor/bootstrap/css/bootstrap.min.css";
import "./assets/vendor/font-awesome/css/all.min.css";
import "./assets/vendor/bootstrap-select/css/bootstrap-select.min.css";
import "./assets/vendor/currency-flags/css/currency-flags.min.css";
import "./assets/vendor/daterangepicker/daterangepicker.css";
import "./assets/vendor/owl.carousel/assets/owl.carousel.min.css";
import "./assets/css/stylesheet.css";

import {
  LoginM,
  SignUp,
  ForgotPass,
  DashboardM,
  ChangePassword,
  TransactionPage,
  NotificationPage,
  Home,
  Withdraw,
  RequestMoney,
  Refund,
  Store,
  AddStore,
  NotFound,
  Wrapper,
  MuiPickersUtilsProvider,
  MomentUtils,
  mapboxgl,
  Redirect,
} from "./common";
import CashOut from "./components/CashOut";
import KycStatus from "./components/kyc/KycStatus";
import KybStatus from "./components/kyb/KybStatus";
import Signed_document from "./components/kyb/Signed_document";
import Voucher from "./components/Voucher";
import VoucherCounter from "./screens-counter/dashboard/Transactions/VoucherCounter";
import ChangeLogo from "./components/ChangeLogo";

mapboxgl.accessToken =
  "pk.eyJ1IjoibW96YW1tZWwtbmV3cm96dGVjaCIsImEiOiJja3UzdXVzcDkzM2NpMm9xdHpvaWZvb3oxIn0.Rv2Zi9wSlzo2Jxa3Zdri4A";

mapboxgl.setRTLTextPlugin(
  "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js",
  null,
  true // Lazy load the plugin
);

ReactDOM.render(
  <Wrapper>
    <React.Fragment>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        {/* <Navbar /> */}
        <Router forceRefresh={true}>
          <Switch>
            {/* Counter Routes */}
            {!isAuthenticate() && (
              <RouteWithLayout
                exact
                layout={Main}
                path={["/counter-panel", "/counter-panel/login"]}
                component={Login}
              />
            )}

            <RouteWithLayout
              exact
              layout={Main}
              path={["/counter-panel", "/counter-panel/login"]}
              component={Login}
            />

            <RouteWithLayout
              layout={Main}
              path="/counter-panel/forgot-password"
              component={ForgotPassword}
            />
            <PrivateRouteWithLayout
              path="/counter-panel/dashboard"
              component={Dashboard}
            />
            <PrivateRouteWithLayout
              path="/counter-panel/basic-info"
              component={BasicInfo}
            />
            <PrivateRouteWithLayout
              path="/counter-panel/transactions"
              component={Transactions}
            />
            <PrivateRouteWithLayout
              path="/counter-panel/voucher"
              component={VoucherCounter}
            />

            {/* Merchant Panel */}
            <Route path="/forgotpassword" component={ForgotPass} />
            {/* <Route path="/home" component={Home} /> */}
            <Route path="/dashboard" component={DashboardM} />
            <Route path="/changepassword" component={ChangePassword} />
            <Route path="/change-logo" component={ChangeLogo} />
            <Route path="/transactions" component={TransactionPage} />
            <Route path="/notifications" component={NotificationPage} />
            <Route path="/store/add-store" component={AddStore} />
            <Route path="/store" component={Store} />
            <Route path="/voucher" component={Voucher} />

            <Route path="/withdraw" component={Withdraw} />
            <Route path="/transfer" component={CashOut} />
            <Route path="/requestpayment" component={RequestMoney} />
            {/* <Route path="/deposit" component={Deposit} /> */}
            <Route path="/refund" component={Refund} />

            <Route path="/kyc-status" component={KycStatus} />
            <Route path="/kyb-status" component={KybStatus} />
            <Route path="/signed_document" component={Signed_document} />

            <Route path="/signup" component={SignUp} />
            <Route path="/not-found" component={NotFound} />
            <Route exact path={["/", "/login"]} component={LoginM} />
            <Redirect to="/not-found" />
          </Switch>
        </Router>
      </MuiPickersUtilsProvider>
    </React.Fragment>
  </Wrapper>,

  document.getElementById("root")
);
