import React, { useState, useEffect } from "react";
import axios from "axios";
import authHeader from "../../services/auth-header";
import hostAPI from "../../services/GetHost";
import Alert from "@material-ui/lab/Alert";
import {
  Navbar,
  Vnavbar,
  SideBar,
  //  Chart,
  Footer,
  MobileView,
  FormattedMessage,
  useHistory,
  StyleSheet,
  Button,
} from "../../common";

const styless = StyleSheet.create({
  box: {
    width: 600,
    marginTop: 100,
    padding: 40,
    border: "1px solid #ddd",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: 15,
    boxShadow: "0px 0px 5px 3px #eee",
  },
  input: {
    marginBottom: 20,
  },
  image: {
    width: 200,
  },
});

export default function KycStatus() {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [pin, setPin] = useState("");

  const [attachment, set_attachment] = useState(null);
  const [image, setImage] = useState("");

  const [attachment2, set_attachment2] = useState(null);
  const [image2, setImage2] = useState("");

  const [attachment3, set_attachment3] = useState(null);
  const [image3, setImage3] = useState("");

  const [NidScreen, setNidScreen] = useState(true);
  const [PhotoScreen, setPhotoScreen] = useState(false);
  const [PinScreen, setPinScreen] = useState(false);
  const [successScreen, setSuccessScreen] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const loadFile = function (event) {
    var image = document.getElementById("output");
    image.src = URL.createObjectURL(event.target.files[0]);
  };

  const loadFile2 = function (event) {
    var image = document.getElementById("output2");
    image.src = URL.createObjectURL(event.target.files[0]);
  };

  const loadFile3 = function (event) {
    var image = document.getElementById("output3");
    image.src = URL.createObjectURL(event.target.files[0]);
  };

  const handleSubmit = async () => {
    setLoading(true);
    // return console.log("testing");

    try {
      let data = new FormData();

      data.append("document_type_id", 1);
      data.append("document_copy", attachment);
      data.append("document_copy_2", attachment2);

      let url = `${hostAPI.getHost()}api/v1/private/kyc/upload-verification-document`;
      let res = await axios({
        url: url,
        method: "post",
        data: data,
        headers: authHeader(),
      });

      console.log("testing", res.data);
      if (res.data.code === 200) {
        setNidScreen(false);
        setPhotoScreen(true);
        setErrorMessage("");
      } else {
        setErrorMessage(JSON.stringify(res.data.messages));
      }
    } catch (error) {
      console.log(error.response);
    }

    setLoading(false);
  };
  const handleSubmitPhoto = async () => {
    setLoading(true);

    try {
      let data = new FormData();

      data.append("photo", attachment3);

      let url = `${hostAPI.getHost()}api/v1/private/kyc/upload-photo`;
      let res = await axios({
        url: url,
        method: "post",
        data: data,
        headers: authHeader(),
      });

      if (res.data.code === 500) {
        setErrorMessage("Image is oversized, Upload another image");
      }

      if (res.data.code === 200) {
        setNidScreen(false);
        setPhotoScreen(false);
        setPinScreen(true);
        setErrorMessage("");
      } else {
        setErrorMessage(JSON.stringify(res.data.messages));
      }
    } catch (error) {
      console.log(error.response);
    }

    setLoading(false);
  };

  const handleSubmitPin = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let data = new FormData();

      data.append("pin", pin);

      let url = `${hostAPI.getHost()}api/v1/private/kyc/set-security-pin`;
      let res = await axios({
        url: url,
        method: "post",
        data: data,
        headers: authHeader(),
      });
      if (res.data.code === 200) {
        setNidScreen(false);
        setPhotoScreen(false);
        setPinScreen(false);
        setSuccessScreen(true);
        setErrorMessage("");

        setTimeout(() => {
          history.push("/kyb-status");
        }, 3000);
      } else {
        setErrorMessage(JSON.stringify(res.data.messages));
      }
    } catch (error) {
      console.log(error.response);
    }

    setLoading(false);
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const kyc_status = params.get("kyc_status");
    const user_has_pin = params.get("user_has_pin");
    if (kyc_status == 2 || kyc_status == 3 || kyc_status == 7) {
      setNidScreen(false);
      setPhotoScreen(true);
    }
    if (user_has_pin == 1) {
      setNidScreen(false);
      setPinScreen(true);
    }
  }, []);

  return (
    <React.Fragment>
      <div>
        <div
          className="WebView"
          style={{ textAlign: `${localStorage.getItem("LocalTextAlign")}` }}
        >
          <Navbar />

          <div id="content">
            <div>
              <div
                className=" asideTabView"
                style={{ backgroundColor: "#F0F6FA" }}
              >
                <div className="col-lg-1 pt-4">
                  <Vnavbar />
                </div>
                <br />
                <div className="pt-4">
                  <SideBar />
                </div>
              </div>

              <div className="row" style={{ margin: "0" }}>
                <div
                  className="col-lg-1 pt-4 asideDesktopView SideDesktopView"
                  style={{ backgroundColor: "#F0F6FA" }}
                >
                  <Vnavbar />
                </div>
                <div
                  className="col-lg-3 col-s-12 pt-4 asideDesktopView SideDesktopView"
                  style={{ backgroundColor: "#F0F6FA" }}
                >
                  <SideBar />
                </div>

                <div className="col-lg-8 col-s-12 pt-4">
                  <h4
                    className="font-weight-600 mb-3"
                    style={{ color: "#2D335B" }}
                  >
                    Set KYC
                  </h4>
                  {errorMessage && (
                    <Alert severity="error"> {errorMessage} </Alert>
                  )}

                  {NidScreen && (
                    <div style={styless.box}>
                      <h3 className="text-5 font-weight-600 mb-3 mb-sm-4 text-center">
                        Upload your NID
                      </h3>
                      <h6 style={styless.red}>NID Front</h6>
                      <input
                        type="file"
                        className="form-control MerchantInput"
                        style={styless.input}
                        id="actual-btn"
                        onChange={(e) => {
                          set_attachment(e.target.files[0]);
                          loadFile(e);
                        }}
                        name="attachment"
                      />

                      <img
                        src={image}
                        alt=""
                        id="output"
                        style={styless.image}
                      />

                      <h6 style={styless.red}>NID Back</h6>
                      <input
                        type="file"
                        className="form-control MerchantInput"
                        style={styless.input}
                        id="actual-btn"
                        onChange={(e) => {
                          set_attachment2(e.target.files[0]);
                          loadFile2(e);
                        }}
                        name="attachment2"
                      />
                      <img
                        src={image2}
                        alt=""
                        id="output2"
                        style={styless.image}
                      />

                      <button
                        className="btn btn-primary"
                        style={{
                          borderRadius: "50px",
                          width: "100%",
                        }}
                        onClick={handleSubmit}
                      >
                        {loading && (
                          <span
                            className="spinner-border spinner-border-sm"
                            style={{ marginRight: 10 }}
                          ></span>
                        )}
                        Continue
                      </button>
                    </div>
                  )}

                  {PhotoScreen && (
                    <div style={styless.box}>
                      <h3 className="text-5 font-weight-600 mb-3 mb-sm-4 text-center">
                        Upload Your Photo
                      </h3>
                      <h6 style={styless.red}>Photo</h6>
                      <input
                        type="file"
                        className="form-control MerchantInput"
                        style={styless.input}
                        id="actual-btn"
                        onChange={(e) => {
                          set_attachment3(e.target.files[0]);
                          loadFile3(e);
                        }}
                        name="attachment3"
                      />

                      <img
                        src={image3}
                        alt=""
                        id="output3"
                        style={styless.image}
                      />

                      <button
                        className="btn btn-primary"
                        style={{
                          borderRadius: "50px",
                          width: "100%",
                        }}
                        onClick={handleSubmitPhoto}
                      >
                        {loading && (
                          <span
                            className="spinner-border spinner-border-sm"
                            style={{ marginRight: 10 }}
                          ></span>
                        )}
                        Continue
                      </button>
                    </div>
                  )}

                  {PinScreen && (
                    <div style={styless.box}>
                      <form onSubmit={handleSubmitPin}>
                        <h3 className="text-5 font-weight-600 mb-3 mb-sm-4 text-center">
                          Set YourTransaction PIN
                        </h3>
                        <h6 style={styless.red}>Pin</h6>
                        <input
                          type="number"
                          max="9999"
                          className="form-control MerchantInput"
                          style={styless.input}
                          id="actual-btn"
                          onChange={(e) => {
                            setPin(e.target.value);
                          }}
                        />

                        <button
                          className="btn btn-primary"
                          style={{
                            borderRadius: "50px",
                            width: "100%",
                          }}
                          type="submit"
                        >
                          {loading && (
                            <span
                              className="spinner-border spinner-border-sm"
                              style={{ marginRight: 10 }}
                            ></span>
                          )}
                          Submit
                        </button>
                      </form>
                    </div>
                  )}

                  {successScreen && (
                    <div style={styless.box}>
                      <h3 className="text-5 font-weight-600 mb-3 mb-sm-4 text-center">
                        Successfully Done
                      </h3>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>

        <MobileView />
      </div>
    </React.Fragment>
  );
}
