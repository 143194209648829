import React from "react";
import Logo from "../assets/images/stpay.png";
import LogoWhite from "../assets/images/stpay_logo_white.png";

export default function NotFound() {
  return (
    <React.Fragment>
      <div
        className="WebView"
        style={{ textAlign: `${localStorage.getItem("LocalTextAlign")}` }}
      >
        <header>
          <div className="container">
            <div className="header-row pt-4 pb-4">
              <div className="header-column justify-content-start">
                <div className="logo">
                    <img src={LogoWhite} alt="ST Pay" width={120} />
                </div>
              </div>
            </div>
          </div>
        </header>

        <section className="page-header page-header-text-light bg-secondary py-5 mb-0">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-8 order-1 order-md-0">
                <h1>404 - Page not found</h1>
              </div>
              <div className="col-md-4 order-0 order-md-1">
                <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
                  <li className="active">404 - Page Not Found</li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <div id="content">
          <section className="section">
            <div className="container text-center">
              <h2 className="text-25 mb-0">404</h2>
              <h3 className="text-6 mb-3">
                oops! The page you requested was not found!
              </h3>
              <p className="text-3 text-muted">
                The page you are looking for was moved, removed, renamed or
                might never existed.
              </p>
              <a
                href="/dashboard"
                className="btn btn-outline-dark shadow-none px-5 m-2"
              >
                Login
              </a>
            </div>
          </section>
        </div>
      </div>
    </React.Fragment>
  );
}
