import React from "react";
import {
  FormattedMessage,
  Notifications,
  Navbar,
  Vnavbar,
  Footer,
  MobileView,
  SideBar,
} from "../common";

export default function TransactionPage(props) {

  return (
    <React.Fragment>
      <div>
        <div className="WebView" style={{ textAlign: `${localStorage.getItem("LocalTextAlign")}`}}>
          <Navbar />

          <div id="content">
            <div>
              <div
                className=" asideTabView"
                style={{ backgroundColor: "#F0F6FA" }}
              >
                <div className="col-lg-1 pt-4">
                  <Vnavbar />
                </div>
                <br />
                <div className="pt-4">
                  <SideBar />
                </div>
              </div>
              <div className="row" style={{ margin: "0" }}>
                <div
                  className="col-lg-1 pt-4 asideDesktopView SideDesktopView"
                  style={{ backgroundColor: "#F0F6FA" }}
                >
                  <Vnavbar />
                </div>
                <div
                  className="col-lg-3 col-s-12 pt-4 asideDesktopView SideDesktopView"
                  style={{ backgroundColor: "#F0F6FA" }}
                >
                  <SideBar />
                </div>

                <div className="col-lg-8 col-s-12 pt-4">
                  <h5 className="font-weight-500 mb-3">
                    <FormattedMessage
                      id="All_Notifications"
                      defaultMessage="All Notifications"
                    />
                  </h5>
                  <Notifications />
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
        <MobileView />
      </div>
    </React.Fragment>
  );
}
