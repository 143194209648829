import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";

import {
  Box,
  Container,
  IconButton,
  Snackbar,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  CircularProgress,
  Breadcrumbs,
  HomeIcon,
  MuiAlert,
  NavLink,
  makeStyles,
  withStyles,
  useTheme,
  commonService,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  AddBoxIcon,
  SpeedIcon,
} from "../../../common-counter";

import hostAPI from "../../../services/GetHost";
let Base_URL = process.env.REACT_APP_BASE_URL2;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "5rem",
  },
  btnStyle: {
    backgroundColor: "#1DBF73",
    color: "white",
    fontWeight: "400",
    "&:hover": {
      backgroundColor: "#11a962",
    },
  },
  listIcon: { width: 20, paddingRight: theme.spacing(0.5) },

  table: {
    marginTop: "1.5rem",
  },
  rowPadding: {
    padding: "0.125rem 0.25rem 0 0",
  },
  formControl: {
    margin: theme.spacing(1),
    // minWidth: 120,
  },
  selectEmpty: {
    // marginTop: theme.spacing(2),
  },
  link: {
    display: "flex",
    textDecoration: "none",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  active: {
    border: "1px solid #1DBF73",
    textTransform: "Capitalize",
    color: "#1DBF73",
    backgroundColor: "#1DBF7333",
  },
  disabled: {
    border: "1px solid #1DBF73",
    textTransform: "Capitalize",
    color: "#BEBDBD",
  },
  borderRemove: {
    borderBottom: "none",
    paddingLeft: "0",
  },
  btnIcon: {
    paddingRight: "5px",
    fontSize: "20px",
  },
}));

const styless = {
  page: {
    //   flexDirection: 'row',
    //   backgroundColor: '#E4E4E4',
    padding: "15px",
    fontSize: "12px",
    lineHeight: "1.5",
  },
  table: {
    display: "table",
    width: "auto",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    padding: 7,
    borderBottom: "1px solid #ccc",
  },
  tableCol: {
    width: "50%",
  },
  tableCell: {
    marginTop: 5,
    color: "#2d335b",
  },
  tableCell2: {
    marginTop: 5,
    color: "#8e9a9d",
    textAlign: "right",
  },
  transactionStyle: {
    color: "#1DBF73",
    marginTop: 5,
    textAlign: "right",
  },
  amountStyle: {
    color: "#008046",
    marginTop: 5,
    textAlign: "right",
  },
  logo: {
    color: "#008046",
    textAlign: "center",
    fontSize: "18px",
    fontWeight: "800",
  },
  logo2: {
    color: "#2b335e",
    textAlign: "center",
    fontSize: "18px",
    fontWeight: "800",
  },
  invoiceTitle: {
    padding: 7,
    fontWeight: "800",
    paddingBottom: "10px",
  },
  input_left: {
    width: "63px",
    backgroundColor: "#fff",
    borderRadius: "16px 0 0 16px",
    borderLeft: "1px solid #3494E629",
    borderTop: "1px solid #3494E629",
    borderBottom: "1px solid #3494E629",
    borderRight: "none",
  },
  input_right: {
    borderRadius: "30px",
    borderLeft: "1px solid #3494E629",
    borderRight: "1px solid #3494E629",
    borderTop: "1px solid #3494E629",
    borderBottom: "1px solid #3494E629",
  },
};

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));
export default function VoucherCounter() {
  const classes = useStyles();
  const classes1 = useStyles1();
  const theme = useTheme();

  const [vouchers, setVouchers] = useState([]);
  const [voucher, setVoucher] = useState("");
  const [list, setList] = useState(true);
  const [details, setDetails] = useState({});
  const [detailScreen, setDetailScreen] = useState(false);
  const [successScreen, setSuccessScreen] = useState(false);

  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [successMsgBody, setSuccessMsgBody] = useState("");
  const [error, setError] = useState(false);
  const [errorMsgBody, setErrorMsgBody] = useState("");

  const getVoucher = async () => {
    setLoading(true);
    try {
      const accessToken = Cookies.getJSON("counter_acc");

      let res = await axios({
        url: `${Base_URL}counter-panel-api/v1/voucher/list`,
        method: "get",
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      console.log("bene", res);
      if (res.data.data.length > 0) {
        setVouchers(res.data.data);
      }
    } catch (error) {
      console.log("err", error);
    }
    setLoading(false);
  };

  const handleVoucher = async (e) => {
    e.preventDefault();

    setLoading(true);
    setMessage("");
    setSuccessScreen(false);

    try {
      const accessToken = Cookies.getJSON("counter_acc");

      let res = await axios({
        url: `${Base_URL}counter-panel-api/v1/voucher/validation`,
        method: "post",
        headers: { Authorization: `Bearer ${accessToken}` },

        data: {
          voucher_code: voucher,
        },
      });
      // setVoucher("");
      setDetailScreen(true);
      setOpen(false);
      setList(false);
      if (res.data.data.length > 0) {
        res.data.data[0] && setDetails(res.data.data[0]);
        setOpen(false);
      } else {
        setDetailScreen(false);
        setMessage("Not found");
      }
    } catch (error) {
      console.log("err", error);
      setDetailScreen(false);

      setMessage("Something is wrong");
    }
    setLoading(false);
  };

  const handleRedeem = async (e) => {
    e.preventDefault();
    // return setSuccessScreen(true);
    setLoading(true);
    setMessage("");

    try {
      const accessToken = Cookies.getJSON("counter_acc");
      let res = await axios({
        url: `${Base_URL}counter-panel-api/v1/voucher/redeem`,
        method: "post",
        headers: { Authorization: `Bearer ${accessToken}` },

        data: {
          voucher_code: voucher,
        },
      });
      setVoucher("");
      setDetailScreen(false);
      setSuccessScreen(true);
      setOpen(false);
    } catch (error) {
      setDetailScreen(false);
      setMessage("Something is wrong");
    }
    setLoading(false);
  };

  const resetAll = () => {
    setDetailScreen(false);
    setSuccessScreen(false);
    setList(true);
    setMessage("");
  };

  const handleErrorClose = (event) => {
    setError(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    getVoucher();
  }, []);

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Snackbar
        open={open}
        autoHideDuration={1500}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="success">
          {successMsgBody}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={error}
        autoHideDuration={1500}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          {errorMsgBody}
        </Alert>
      </Snackbar>
      <Breadcrumbs style={{ marginTop: "10px" }} aria-label="breadcrumb">
        <NavLink
          to="/counter-panel/dashboard"
          style={{ color: "#2B335E99" }}
          className={classes.link}
        >
          <HomeIcon className={classes.icon} />
          Home
        </NavLink>
        <NavLink
          to="/counter-panel/voucher"
          style={{ color: "#2B335E99" }}
          className={classes.link}
        >
          <SpeedIcon className={classes.icon} />
          Voucher
        </NavLink>
        <Typography style={{ color: "#2B335E" }} className={classes.link}>
          <AddBoxIcon className={classes.listIcon} /> List
        </Typography>
      </Breadcrumbs>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          style={{
            textAlign: "left",
            margin: "15px 0",
            color: "#2B335E",
            fontSize: "25px",
          }}
        >
          Voucher List
        </Typography>
      </div>

      <div className="col-lg-12 col-s-12 pt-4">
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              {/* <label htmlFor="amount" className="font-weight-600">
                        <FormattedMessage
                          id="account_name"
                          defaultMessage="Voucher"
                        />
                      </label> */}
              <form onSubmit={handleVoucher}>
                <div className="input-group" style={{ direction: "ltr" }}>
                  <input
                    className="form-control "
                    style={styless.input_right}
                    placeholder="Enter Voucher Code"
                    required
                    type="text"
                    value={voucher}
                    onChange={(e) => setVoucher(e.target.value)}
                  />
                  <button
                    className="btn btn-primary"
                    type="submit"
                    style={{ borderRadius: 30, marginLeft: 10 }}
                  >
                    Validate
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-12">
            {list && <h5>Voucher list</h5>}
            {list && (
              <Paper
                sx={{ width: "100%" }}
                style={{
                  maxHeight: "calc(100vh - 100px)",
                  overflow: "auto",
                }}
              >
                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Title</TableCell>
                        {/* <TableCell>Operator</TableCell> */}
                        <TableCell>Face value</TableCell>
                        <TableCell>Sell price</TableCell>
                        <TableCell>Expired at</TableCell>
                        <TableCell>Total Card</TableCell>
                        <TableCell>Unused</TableCell>
                        <TableCell>Purchased</TableCell>
                        <TableCell>Redeemed</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {vouchers.length > 0 &&
                        vouchers.map((item, index) => (
                          <TableRow hover role="checkbox" tabIndex={-1}>
                            <TableCell>{item.bundle_name}</TableCell>
                            {/* <TableCell>{item.operator}</TableCell> */}
                            <TableCell>{item.face_value}</TableCell>
                            <TableCell>{item.sell_price}</TableCell>
                            <TableCell>{item.expired_at}</TableCell>
                            <TableCell>{item.total_card}</TableCell>
                            <TableCell>{item.unused}</TableCell>
                            <TableCell>{item.purchased}</TableCell>
                            <TableCell>{item.redeemed}</TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            )}

            {detailScreen && (
              <Paper
                sx={{ width: "100%" }}
                style={{
                  maxHeight: "calc(100vh - 100px)",
                  overflow: "auto",
                }}
              >
                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Title</TableCell>
                        <TableCell>{details.bundle_name}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Brand</TableCell>
                        <TableCell>{details.operator}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Value</TableCell>
                        <TableCell>{details.face_value}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Voucher Code</TableCell>
                        <TableCell>{details.voucher_code}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Expire at</TableCell>
                        <TableCell>{details.expired_at}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          {details.status !== 1 && "Status"}
                        </TableCell>
                        <TableCell>
                          {details.status === 0 && (
                            <button
                              className="btn btn-secondary btn-sm"
                              style={{ borderRadius: 30 }}
                              // disabled
                            >
                              voucher not sold yet
                            </button>
                          )}

                          {details.status === 2 && (
                            <button
                              className="btn btn-secondary btn-sm"
                              style={{ borderRadius: 30 }}
                            >
                              voucher already used
                            </button>
                          )}

                          {details.status === 1 && (
                            <button
                              className="btn btn-primary"
                              type="submit"
                              style={{ borderRadius: 30 }}
                              onClick={handleRedeem}
                            >
                              Redeem Voucher
                            </button>
                          )}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </TableContainer>
              </Paper>
            )}

            {successScreen && (
              <Paper
                sx={{ width: "100%" }}
                style={{
                  fontSize: "2rem",
                  textAlign: "center",
                  padding: 100,
                }}
              >
                Successfully Redeemed
                <br />
                <br />
                <br />
                <button
                  className="btn btn-primary"
                  type="submit"
                  style={{ borderRadius: 30, marginLeft: 10 }}
                  onClick={resetAll}
                >
                  Back to list
                </button>
              </Paper>
            )}

            {message && (
              <div id="fail" style={{ paddingTop: "10%" }}>
                <div>
                  <div className="row">
                    <div className="col-md-9 col-lg-8 col-xl-6 mx-auto">
                      <div
                        className="bg-white text-center shadow-md  p-3 pt-sm-4 pb-sm-5 px-sm-5 mb-4"
                        style={{ borderRadius: "16px" }}
                      >
                        <div className="my-4">
                          <p className="text-danger text-20 line-height-07">
                            <i className="fas fa-times-circle"></i>
                          </p>
                        </div>
                        <p className="text-3 mb-4">
                          <span className="text-4 font-weight-500">
                            {/* {this.state.message} */}
                            {message}
                          </span>{" "}
                          <br />
                          <br />
                          <button
                            className="btn btn-primary"
                            to="#"
                            onClick={resetAll}
                          >
                            Try Again
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {loading && (
              <div className="" style={{ textAlign: "center", padding: 30 }}>
                <CircularProgress />
              </div>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
}
