import React, { useState, useEffect } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import {
  Cookies,
  FormattedMessage,
  hostAPI,
  axios,
  StyleSheet,
  CircularProgress,
  authHeader,
  Navbar,
  Vnavbar,
  Footer,
  MobileView,
  SideBar,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Card,
  CardContent,
} from "../common";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

let Base_URL = `${hostAPI.getHost()}`;

const styless = StyleSheet.create({
  page: {
    //   flexDirection: 'row',
    //   backgroundColor: '#E4E4E4',
    padding: "15px",
    fontSize: "12px",
    lineHeight: "1.5",
  },
  table: {
    display: "table",
    width: "auto",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    padding: 7,
    borderBottom: "1px solid #ccc",
  },
  tableCol: {
    width: "50%",
  },
  tableCell: {
    marginTop: 5,
    color: "#2d335b",
  },
  tableCell2: {
    marginTop: 5,
    color: "#8e9a9d",
    textAlign: "right",
  },
  transactionStyle: {
    color: "#1DBF73",
    marginTop: 5,
    textAlign: "right",
  },
  amountStyle: {
    color: "#008046",
    marginTop: 5,
    textAlign: "right",
  },
  logo: {
    color: "#008046",
    textAlign: "center",
    fontSize: "18px",
    fontWeight: "800",
  },
  logo2: {
    color: "#2b335e",
    textAlign: "center",
    fontSize: "18px",
    fontWeight: "800",
  },
  invoiceTitle: {
    padding: 7,
    fontWeight: "800",
    paddingBottom: "10px",
  },
  input_left: {
    width: "63px",
    backgroundColor: "#fff",
    borderRadius: "16px 0 0 16px",
    borderLeft: "1px solid #3494E629",
    borderTop: "1px solid #3494E629",
    borderBottom: "1px solid #3494E629",
    borderRight: "none",
  },
  input_right: {
    borderRadius: "16px 16px 16px 16px",
    borderLeft: "1px solid #3494E629",
    borderRight: "1px solid #3494E629",
    borderTop: "1px solid #3494E629",
    borderBottom: "1px solid #3494E629",
  },
});

const CashOut = () => {
  const [beneficiary, setBeneficiary] = useState([]);
  const [banks, setBanks] = useState([]);
  const [branches, setBranches] = useState([]);
  const [bank, setBank] = useState("");
  const [branch, setBranch] = useState("");
  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [beneficiary_id, setBeneficiary_id] = useState("");
  const [amount, setAmount] = useState("");
  const [pin, setPin] = useState("");
  const [list, setList] = useState(true);
  const [cashOutOne, setCashOutOne] = useState(false);
  const [cashOutTwo, setCashOutTwo] = useState(false);
  const [cashOutThree, setCashOutThree] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const getBeneficiary = async () => {
    setLoading(true);
    try {
      let res = await axios({
        url: `${Base_URL}api/v1/private/wallet/withdraw-money-bank/beneficiary-list`,
        method: "get",
        headers: authHeader(),
      });

      console.log("bene", res.data.data.beneficiaries.verified);
      setBeneficiary(res.data.data.beneficiaries.verified);
    } catch (error) {
      console.log("err", error);
    }
    setLoading(false);
  };

  const getBank = async () => {
    try {
      let res = await axios({
        url: `${Base_URL}api/v1/private/banks`,
        method: "get",
        headers: authHeader(),
      });

      console.log("banks", res.data.data.banks);
      setBanks(res.data.data.banks);
    } catch (error) {
      console.log("err", error);
    }
  };

  const getBranch = async (id) => {
    try {
      let res = await axios({
        url: `${Base_URL}api/v1/bank/branches?bank_id=${id}`,
        method: "get",
        headers: authHeader(),
      });

      console.log("branch", res.data.data.branches);
      setBranches(res.data.data.branches);
    } catch (error) {
      console.log("err", error);
    }
  };

  const handleBank = (e) => {
    setBank(e.target.value);
    getBranch(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (accountNumber.length > 20) {
      return;
    }
    setLoading(true);
    setOpen(true);
    try {
      let res = await axios({
        url: `${Base_URL}api/v1/private/wallet/withdraw-money-bank/beneficiary-add`,
        method: "post",
        headers: authHeader(),
        data: {
          bank_id: bank,
          bank_branch_id: branch,
          account_name: accountName,
          account_no: accountNumber,
        },
      });
      setBranch("");
      setBank("");
      setAccountNumber("");
      setAccountName("");
      getBeneficiary();

      console.log("post", res.data);
    } catch (error) {
      console.log("err", error);
      setMessage("fail message");
    }
    setLoading(false);
  };

  const handleCashOut = (id) => {
    console.log("id:", id);
    setBeneficiary_id(id);
    setList(false);
    setCashOutOne(true);
  };

  const handleContinue = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let res = await axios({
        url: `${Base_URL}api/v1/private/transaction/withdraw-money-bank-request/summary`,
        method: "post",
        headers: authHeader(),
        data: {
          beneficiary_id: beneficiary_id,
          amount: amount,
        },
      });

      console.log("post", res.data);

      if (res.data.code !== 200) {
        console.log("err", res.data.messages.toString());
        setMessage(res.data.messages.toString());
      } else {
        setCashOutTwo(true);
        setMessage("");
      }
    } catch (error) {
      console.log("err", error);
      // setMessage(error.response.messages.toString());
    }
    setLoading(false);
  };

  const handleConfirm = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let res = await axios({
        url: `${Base_URL}api/v1/private/transaction/withdraw-money-bank-request/execute`,
        method: "post",
        headers: authHeader(),
        data: {
          beneficiary_id: beneficiary_id,
          amount: amount,
          pin: pin,
        },
      });

      if (res.data.code !== 200) {
        console.log("err", res.data.messages.toString());
        setMessage(res.data.messages.toString());
      } else {
        setCashOutOne(false);
        setCashOutTwo(false);
        setCashOutThree(true);
        setMessage("");
      }
    } catch (error) {
      console.log("err", error);
    }
    setLoading(false);
  };

  const resetAll = () => {
    setCashOutOne(false);
    setCashOutTwo(false);
    setCashOutThree(false);
    setList(true);
    setMessage("");
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    getBeneficiary();
    getBank();
  }, []);
  return (
    <React.Fragment>
      <div
        className="WebView"
        style={{ textAlign: `${localStorage.getItem("LocalTextAlign")}` }}
      >
        <Navbar />
        <div id="content">
          <div>
            <div
              className=" asideTabView"
              style={{ backgroundColor: "#F0F6FA" }}
            >
              <div className="col-lg-1 pt-4">
                <Vnavbar />
              </div>
              <br />
              <div className="pt-4">
                <SideBar />
              </div>
            </div>
            <div className="row" style={{ margin: "0" }}>
              <div
                className="col-lg-1 pt-4 asideDesktopView SideDesktopView"
                style={{ backgroundColor: "#F0F6FA" }}
              >
                <Vnavbar />
              </div>
              <br />
              <div
                className="col-lg-3 col-s-12 pt-4 asideDesktopView SideDesktopView"
                style={{ backgroundColor: "#F0F6FA" }}
              >
                <SideBar />
              </div>

              <div className="col-lg-8 col-s-12 pt-4">
                <div className="row">
                  <div className="col-lg-8">
                    {list && (
                      <Paper
                        sx={{ width: "100%" }}
                        style={{
                          maxHeight: "calc(100vh - 100px)",
                          overflow: "auto",
                        }}
                      >
                        <TableContainer sx={{ maxHeight: 440 }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                <TableCell>AC Number</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Bank Name</TableCell>
                                <TableCell>Branch Name</TableCell>
                                <TableCell>Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {beneficiary.map((item, index) => (
                                <TableRow hover role="checkbox" tabIndex={-1}>
                                  <TableCell>{item.ac_no}</TableCell>
                                  <TableCell>{item.name}</TableCell>
                                  <TableCell>
                                    {" "}
                                    <img
                                      src={item.bank.logo}
                                      alt=""
                                      style={{ marginRight: 20 }}
                                    />{" "}
                                    {item.bank.bank_name}
                                  </TableCell>
                                  <TableCell>{item.branch.name}</TableCell>
                                  <TableCell>
                                    <button
                                      className="btn btn-primary"
                                      onClick={(e) => handleCashOut(item.id)}
                                    >
                                      Transfer
                                    </button>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Paper>
                    )}

                    {(cashOutOne || cashOutTwo) && (
                      <div
                        className=""
                        style={{ maxWidth: 600, margin: "auto" }}
                      >
                        <Card>
                          <CardContent>
                            <h3 class="text-5 font-weight-600 mb-3 mb-sm-4 text-center">
                              Transfer
                            </h3>

                            <form>
                              <div className="form-group">
                                <label
                                  htmlFor="amount"
                                  className="font-weight-600"
                                >
                                  Amount
                                </label>
                                <div
                                  className="input-group"
                                  style={{ direction: "ltr" }}
                                >
                                  <input
                                    className="form-control "
                                    style={styless.input_right}
                                    required
                                    type="number"
                                    value={amount}
                                    onChange={(e) => {
                                      if (e.target.value.length === 9) {
                                        return false;
                                      }
                                      setAmount(parseInt(e.target.value));
                                    }}
                                  />
                                </div>
                              </div>

                              {cashOutTwo && (
                                <div className="form-group">
                                  <label
                                    htmlFor="amount"
                                    className="font-weight-600"
                                  >
                                    Pin
                                  </label>
                                  <div
                                    className="input-group"
                                    style={{ direction: "ltr" }}
                                  >
                                    <input
                                      className="form-control "
                                      style={styless.input_right}
                                      required
                                      type="text"
                                      value={pin}
                                      onChange={(e) => {
                                        setPin(e.target.value);
                                      }}
                                    />
                                  </div>
                                </div>
                              )}

                              {cashOutTwo ? (
                                <div className="text-center">
                                  <button
                                    className="btn btn-primary"
                                    style={{
                                      borderRadius: "50px",
                                      width: "90%",
                                    }}
                                    onClick={handleConfirm}
                                    disabled={loading}
                                  >
                                    confirm
                                  </button>
                                </div>
                              ) : (
                                <div className="text-center">
                                  <button
                                    className="btn btn-primary"
                                    style={{
                                      borderRadius: "50px",
                                      width: "90%",
                                    }}
                                    onClick={handleContinue}
                                    disabled={loading}
                                  >
                                    Continue
                                  </button>
                                </div>
                              )}
                            </form>
                          </CardContent>
                        </Card>
                      </div>
                    )}

                    {message && (
                      <div id="fail" style={{ paddingTop: "10%" }}>
                        <div>
                          <div className="row">
                            <div className="col-md-9 col-lg-8 col-xl-6 mx-auto">
                              <div
                                className="bg-white text-center shadow-md  p-3 pt-sm-4 pb-sm-5 px-sm-5 mb-4"
                                style={{ borderRadius: "16px" }}
                              >
                                <div className="my-4">
                                  <p className="text-danger text-20 line-height-07">
                                    <i className="fas fa-times-circle"></i>
                                  </p>
                                  <p className="text-danger text-8 font-weight-500 line-height-07">
                                    <FormattedMessage
                                      id="app_common_failed"
                                      defaultMessage="Failed"
                                    />{" "}
                                    !
                                  </p>
                                  <p className="lead">
                                    <FormattedMessage
                                      id="Transaction_Failed"
                                      defaultMessage="Transaction Failed"
                                    />
                                  </p>
                                </div>
                                <p className="text-3 mb-4">
                                  <span className="text-4 font-weight-500">
                                    {/* {this.state.message} */}
                                    {message}
                                  </span>{" "}
                                  <br />
                                  <FormattedMessage
                                    id="app_common_try_again"
                                    defaultMessage="Try Again"
                                  />{" "}
                                  <button
                                    className="btn btn-primary"
                                    to="#"
                                    onClick={resetAll}
                                  >
                                    <FormattedMessage
                                      id="app_common_try_again"
                                      defaultMessage="Transfer"
                                    />
                                  </button>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {cashOutThree && (
                      <div id="success" style={{ paddingTop: "10%" }}>
                        <div>
                          <div className="row">
                            <div className="col-md-9 col-lg-8 col-xl-6 mx-auto">
                              <div
                                className="bg-white text-center shadow-md  p-3 pt-sm-4 pb-sm-5 px-sm-5 mb-4"
                                style={{ borderRadius: "16px" }}
                              >
                                <div className="my-4">
                                  <p className="text-success text-20 line-height-07">
                                    <i className="fas fa-check"></i>
                                  </p>
                                  <p className="text-success text-8 font-weight-500 line-height-07">
                                    <FormattedMessage
                                      id="app_common_success"
                                      defaultMessage="Success"
                                    />{" "}
                                    !
                                  </p>
                                  <p className="lead">
                                    <FormattedMessage
                                      id="Transaction_Success"
                                      defaultMessage="Transaction Success"
                                    />
                                  </p>
                                </div>
                                <p className="text-3 mb-4">
                                  <span className="text-4 font-weight-500">
                                    {/* {message} */}
                                  </span>{" "}
                                  <br />
                                  <button
                                    className="btn btn-primary"
                                    to="#"
                                    onClick={resetAll}
                                  >
                                    Done
                                  </button>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {loading && (
                      <div
                        className=""
                        style={{ textAlign: "center", padding: 30 }}
                      >
                        <CircularProgress />
                      </div>
                    )}
                  </div>
                  <div className="col-lg-4">
                    <Snackbar
                      open={open}
                      autoHideDuration={2000}
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                      onClose={handleClose}
                    >
                      <Alert severity="success">
                        Added to Beneficiary List
                      </Alert>
                    </Snackbar>
                    <Card>
                      <CardContent>
                        <h3 class="text-5 font-weight-600 mb-3 mb-sm-4 text-center">
                          <FormattedMessage
                            id="beneficiary_title"
                            defaultMessage="Add New Beneficiary"
                          />
                        </h3>

                        <form onSubmit={handleSubmit}>
                          <div className="form-group">
                            <label htmlFor="amount" className="font-weight-600">
                              <FormattedMessage
                                id="bank"
                                defaultMessage="Bank"
                              />
                            </label>
                            <div
                              className="input-group"
                              style={{ direction: "ltr" }}
                            >
                              <select
                                className="form-control "
                                style={styless.input_right}
                                value={bank}
                                onChange={handleBank}
                              >
                                <option value="" disabled>
                                  Select Bank
                                </option>
                                {banks.map((item, index) => (
                                  <option value={item.bank_id}>
                                    {item.bank_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div className="form-group">
                            <label htmlFor="amount" className="font-weight-600">
                              <FormattedMessage
                                id="branch"
                                defaultMessage="Branch"
                              />
                            </label>
                            <div
                              className="input-group"
                              style={{ direction: "ltr" }}
                            >
                              <select
                                className="form-control "
                                style={styless.input_right}
                                value={branch}
                                onChange={(e) => setBranch(e.target.value)}
                              >
                                <option value="" disabled>
                                  Select Branch
                                </option>
                                {branches.map((item, index) => (
                                  <option value={item.branch_id}>
                                    {item.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div className="form-group">
                            <label htmlFor="amount" className="font-weight-600">
                              <FormattedMessage
                                id="account_name"
                                defaultMessage="Account Name"
                              />
                            </label>
                            <div
                              className="input-group"
                              style={{ direction: "ltr" }}
                            >
                              <input
                                className="form-control "
                                style={styless.input_right}
                                required
                                type="text"
                                value={accountName}
                                onChange={(e) => setAccountName(e.target.value)}
                              />
                            </div>
                          </div>

                          <div className="form-group">
                            <label htmlFor="amount" className="font-weight-600">
                              <FormattedMessage
                                id="account_number"
                                defaultMessage="Account Number"
                              />
                            </label>
                            <div
                              className="input-group"
                              style={{ direction: "ltr" }}
                            >
                              <input
                                className="form-control "
                                style={styless.input_right}
                                required
                                type="text"
                                maxLength="18"
                                value={accountNumber}
                                onChange={(e) =>
                                  setAccountNumber(e.target.value)
                                }
                              />
                            </div>
                            <div style={{ color: "red" }}>
                              {" "}
                              {accountNumber.length > 20
                                ? "Character should be between 20 "
                                : null}{" "}
                            </div>
                          </div>

                          <div className="text-center">
                            <button
                              className="btn btn-primary"
                              style={{
                                borderRadius: "50px",
                                width: "90%",
                              }}
                              type="submit"
                            >
                              {loading && <CircularProgress size={16} />}
                              <FormattedMessage
                                id="beneficiary_button"
                                defaultMessage="Add Beneficiary"
                              />
                            </button>
                          </div>
                        </form>
                      </CardContent>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <MobileView />
    </React.Fragment>
  );
};

export default CashOut;
